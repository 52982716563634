import config from '../lib/config'

import { CrossDomainClientMessageType } from './cross-domain-client-message-type'
import { CrossDomainHostMessageType } from './cross-domain-host-message-type'
import { CrossDomainClientMessage } from './cross-domain-message'
import { CrossDomainService } from './cross-domain-service'

export class CrossDomainClient extends CrossDomainService<CrossDomainClientMessageType, CrossDomainClientMessage<any>> {

  private static createCrossDomainHostFrame(): HTMLIFrameElement {
    const iframe = document.createElement('iframe')
    iframe.src = `${config.urls.seBar}${config.crossDomain.hostPath}`
    iframe.width = '0'
    iframe.height = '0'
    Object.assign(iframe.style, {
      border: 'none',
      position: 'absolute',
      left: '-9999px',
      top: '-9999px',
    })
    return iframe
  }

  private readonly ready: Promise<void>
  private readonly crossdomainFrame: HTMLIFrameElement

  constructor() {
    super(CrossDomainClientMessageType)
    this.crossdomainFrame = CrossDomainClient.createCrossDomainHostFrame()
    this.ready = this.initIFrame()
  }

  public async postMessageToHost(type: CrossDomainHostMessageType, data?: any): Promise<void> {
    await this.ready
    // console.log('[se-bar CrossDomainClient] postMessageToHost', type, data)
    this.crossdomainFrame.contentWindow.postMessage({
      type,
      data,
    }, config.crossDomain.targetOrigin)
  }

  public dispose(): void {
    super.dispose()
    this.crossdomainFrame.remove()
  }

  private initIFrame(): Promise<void> {
    return new Promise<void>(resolve => {
      console.log('[se-bar CrossDomainClient] initIFrame')
      this.crossdomainFrame.addEventListener('load', () => resolve())
      document.body.appendChild(this.crossdomainFrame)
    })
  }

}

