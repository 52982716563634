const resolveSport = (sportName) => {
  switch (sportName) {
    case 'Baseball/Softball':
      return 'baseball'
    case 'Basic':
      return 'team-flag'
    case 'Canadian Football':
      return 'american-football'
    case 'Cross Country':
      return 'cross-country'
    case 'Football':
      return 'american-football'
    case 'Golf':
      return 'team-flag'
    case 'Ice Hockey':
      return 'ice-hockey'
    case 'Other':
      return 'team-flag'
    case 'Paintball':
      return 'team-flag'
    case 'Rugby Union':
      return 'rugby'
    case 'Rugby wheel chair':
      return 'rugby'
    case 'Track and Field':
      return 'team-flag'
    case 'Water Polo':
      return 'water-polo'
    default:
      return sportName.toLowerCase()
  }
}

function camelCase(str) {
  return str.replace(/(-|_|\s)([a-z])/g, function(m) { return m[1].toUpperCase() })
}

function getImageUrlByType(images, type) {
  const image = images.find((image) => image.image_type === type)
  if (image) return image.url
}

export {
  camelCase,
  resolveSport
}
