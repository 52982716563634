import { Observable, Observer, ReplaySubject, TeardownLogic, throwError, TimeoutError } from 'rxjs'
import { catchError, filter, map, share, take, tap, timeout } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'

import { CrossDomainClient } from './cross-domain-client'
import { CrossDomainClientMessageType } from './cross-domain-client-message-type'
import { CrossDomainHostMessageType } from './cross-domain-host-message-type'
import { CrossDomainClientMessage } from './cross-domain-message'
import { CrossDomainMutexEvent } from './cross-domain-mutex-event'
import { MutexLock } from './mutex-lock'
import { MutexRequestAbortedError } from './mutex-request-aborted.error'
import { MutexRequestCanceledError } from './mutex-request-canceled.error'

export type MutexRequestAutoExtend = boolean | number
export interface MutexRequestOptions {
  grantTimeout?: number
  autoExtend?: MutexRequestAutoExtend
}

type MutexClientMessage = CrossDomainClientMessage<CrossDomainMutexEvent>

export class MutexClientRequest<TMutexLock extends MutexLock = MutexLock> extends Observable<TMutexLock> {

  public readonly requestId = uuid()

  private readonly cancel$$ = new ReplaySubject<string>()
  public readonly cancel$ = this.cancel$$.asObservable()

  private _granted: boolean
  public get granted(): boolean {
    return this._granted
  }

  public readonly mutexData: { requestId: string, resourceKey: string }

  private observer: Observer<TMutexLock>

  constructor(
    private xdClient: CrossDomainClient,
    public readonly resourceKey: string,
    public readonly releaseTimeout: number,
    public readonly options: MutexRequestOptions,
  ) {
    super(o => {
      this.observer = o
      return this.init(o)
    })

    this.mutexData = { resourceKey, requestId: this.requestId }
  }

  public cancel(reason: string, ...info: any[]): void {
    console.log('[se-bar MutexClientRequest] cancel: %s', reason, this.mutexData, ...info)
    this.cancel$$.next(reason)
    this.observer.error(new MutexRequestCanceledError(reason))
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.mutexCancel, this.mutexData)
  }

  public abort(reason: string, ...info: any[]): void {
    console.log('[se-bar MutexClientRequest] abort: %s', reason, this.mutexData, ...info)
    this.observer.error(new MutexRequestAbortedError(reason))
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.mutexCancel, this.mutexData)
  }

  private initGranted(): Observable<MutexLock> {
    const timeoutOp = this.options.grantTimeout ?
      timeout(this.options.grantTimeout) :
      tap() // noop

    return this.xdClient.filterMessages<MutexClientMessage>(CrossDomainClientMessageType.mutexGranted).pipe(
      tap(message => console.log('[se-bar MutexClientRequest] received mutexGranted', message.data)),
      filter(message => message.data.requestId === this.requestId),

      // automatically cancel the request if it is not granted within grantTimeout
      timeoutOp,
      catchError((err: Error) => {
        if (err instanceof TimeoutError) {
          this.abort('timeout')
        }
        return throwError(err)
      }),

      take(1),
      map((message: MutexClientMessage) => new MutexLock(this.xdClient, this, message.data)),
      tap(() => this._granted = true),
      share(),
    )
  }

  private init(o: Observer<MutexLock>): TeardownLogic {
    // IMPORTANT! this subscription _must_ be set up before calling postMessageToHost so that the observable is
    // already listening for the response. If it is done outside this constructor, the host may respond on the same
    // "thread" (particularly in a test environment), and the message may have already been missed by the time
    // the pipe is set up to listen for it.
    const grantedSub = this.initGranted().subscribe(o)

    const requestData = Object.assign({ releaseTimeout: this.releaseTimeout }, this.mutexData)
    console.log('[se-bar MutexClientRequest] sending mutexRequest', requestData)
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.mutexRequest, requestData)

    return () => {
      grantedSub.unsubscribe()
    }
  }
}
