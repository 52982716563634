export const templateFn = function anonymous(locals, escapeFn, include, rethrow
) {
"use strict";
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<button type=\"button\" class=\"nb-list-item--clickable nb-menu-buttons-collapsed js-open-chat\">\n  <span class=\"nb-list-item__indicator-icon\">\n    <%- $ctrl.indicator %>\n    <%- $ctrl.icon %>\n  </span>\n  <span class=\"nb-list-item__text\"><%= t('CHAT.chats', { count: $ctrl.messageCount }) %></span>\n  <%- $ctrl.submenuIcon %>\n</button>\n"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  var __locals = (locals || {}),
$ctrl = __locals.$ctrl,
  config = __locals.config,
  formatDate = __locals.formatDate,
  hasTouch = __locals.hasTouch,
  initializeCSSVars = __locals.initializeCSSVars,
  initialsFromFullName = __locals.initialsFromFullName,
  multiToggleFactory = __locals.multiToggleFactory,
  t = __locals.t,
  htmlWebpackPlugin = __locals.htmlWebpackPlugin;
    ; __append("<button type=\"button\" class=\"nb-list-item--clickable nb-menu-buttons-collapsed js-open-chat\">\n  <span class=\"nb-list-item__indicator-icon\">\n    ")
    ; __line = 3
    ; __append( $ctrl.indicator )
    ; __append("\n    ")
    ; __line = 4
    ; __append( $ctrl.icon )
    ; __append("\n  </span>\n  <span class=\"nb-list-item__text\">")
    ; __line = 6
    ; __append(escapeFn( t('CHAT.chats', { count: $ctrl.messageCount }) ))
    ; __append("</span>\n  ")
    ; __line = 7
    ; __append( $ctrl.submenuIcon )
    ; __append("\n</button>\n")
    ; __line = 9
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}; export default templateFn