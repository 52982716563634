import Dropdown from './dropdown'
import ChatList from './chat-list'
import chatService from '../services/chat'
import templateFn from './chat-dropdown.ejs'

class ChatDropdown extends Dropdown {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
  }

  init() {
    super.init()
    this.chatList = new ChatList({ parent: this })
  }

  open() {
    chatService.on('unreadCount', this.updateMessages)
    chatService.getChats(false)
    super.open()
  }

  close() {
    chatService.off('unreadCount', this.updateMessages)
    super.close()
  }

  updateMessages(unreadCount) {
    chatService.getChats(true)
  }
}

export default ChatDropdown
