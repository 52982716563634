function contrastOk(colorOne, colorTwo) {
  const colorOneRgb = hexToRgb(colorOne)
  const colorTwoRgb = hexToRgb(colorTwo)
  if (!colorOneRgb || !colorTwoRgb) return false
  return getContrast(colorOneRgb, colorTwoRgb) > 3
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : undefined
}

function getLuminance(rgb) {
  const a = [rgb.r, rgb.g, rgb.b].map((v) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

function getContrast(colorOne, colorTwo) {
  const result = (getLuminance(colorOne) + 0.05) / (getLuminance(colorTwo) + 0.05)
  return (result < 1) ? 1 / result : result
}

export {
  contrastOk
}
