import config from '../lib/config'
import { UserProfile } from '../lib/user-profile'
import { MessagesService } from '../services/messages'
import { ChatService } from '../services/chat'
import { UserPreferencesService } from '../services/user-preferences'
import { AuthenticatedGlobalSessionState, GlobalSessionEndpoint, SessionStateManager } from '../session-state'

import NginBar, { NginBarConstructor } from './ngin-bar'

export const CACHE_KEY_SESSION_TIMEOUT = 'se-bar:use-session-timeout'
export const CACHE_VALUE_SESSION_TIMEOUT_ENABLED = 'true'

export class SeBarBootstrapper {

  constructor(
    private document: Document,
    private localStorage: Storage,
    private SeBar: NginBarConstructor,
    private sessionStateManagerInit: { init(): SessionStateManager },
    private globalSessionEndpoint: GlobalSessionEndpoint,
    private userPreferencesService: UserPreferencesService,
    private messagesService: MessagesService,
    private chatService: ChatService,
    private beginCacheSession: () => void,
    private loadAllResources: () => Promise<void>,
    private initializeCSSVars: () => void,
  ) {
  }

  public async run(nbid: string, seTag: string, hasTouch: boolean): Promise<NginBar> {

    let sessionStateManager = this.preemptiveInitSessionStateManager()

    this.beginCacheSession()

    const profileReady = this.safeGetSessionProfile()
    const resourcesReady = this.loadAllResources()
    const profile = await profileReady

    sessionStateManager = this.initSessionStateManager(sessionStateManager, profile)
    this.initProfile(profile)

    if (this.initElement(nbid, seTag, hasTouch)) {
      await resourcesReady
      this.initializeCSSVars()

      return new this.SeBar({
        elementId: nbid,
        config,
        profile,
        sessionStateManager,
      })
    }
  }

  private preemptiveInitSessionStateManager(): SessionStateManager {
    if (this.localStorage.getItem(CACHE_KEY_SESSION_TIMEOUT) === CACHE_VALUE_SESSION_TIMEOUT_ENABLED) {
      // init SessionStateManager without waiting for the profile if we've previously set a localStorage value that enables it
      return this.sessionStateManagerInit.init()
    }
    return undefined
  }

  private async safeGetSessionProfile(): Promise<AuthenticatedGlobalSessionState & UserProfile> {
    try {
      return await this.globalSessionEndpoint.fetch().toPromise()
    } catch (err) {
      console.warn('[se-bar SeBarBootStrapper] error retrieving session profile', err)
      return undefined
    }
  }

  private initSessionStateManager(sessionStateManager: SessionStateManager, profile: UserProfile): SessionStateManager {
    if (!profile) {
      return sessionStateManager
    }

    if (profile.pingable) {
      // if session timeout is enabled, persist a value so it can be started ASAP on subsequent requests - this helps
      // enable SE Bar to automatically redirect a user back to what they were doing if they log in from another tab
      this.localStorage.setItem(CACHE_KEY_SESSION_TIMEOUT, CACHE_VALUE_SESSION_TIMEOUT_ENABLED)
      return sessionStateManager || this.sessionStateManagerInit.init()
    }

    this.localStorage.removeItem(CACHE_KEY_SESSION_TIMEOUT)
    if (sessionStateManager) {
      // if for some reason session timeout has been turned off for this user even though it was previously enabled,
      // shut down the session state manager and remove the cross-domain host
      sessionStateManager.dispose()
    }
    return undefined
  }

  private initProfile(profile: UserProfile): void {
    if (!profile) {
      return
    }
    if (profile.preferences) {
      this.userPreferencesService.setPreferences(profile.preferences)
    }

    this.messagesService.setUser(profile) // starts firebase unread count connection
    this.chatService.setUser(profile) // starts firebase unread count connection
  }

  private initElement(nbid: string, seTag: string, hasTouch) {
    const el = this.document.querySelector(`${seTag}, #${nbid}`)
    if (!el) return false // Exit if no element is found
    el.id = nbid // required for current css -- TODO: update CSS to `se-bar { ... }`
    el.classList.add(hasTouch ? 'has-touch' : 'no-touch')
    return true
  }
}
