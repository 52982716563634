import { UnauthorizedGlobalSessionResponseError } from './invalid-global-session-response-error'

export enum RetryableHttpStatus {
  network = -1,
  unauthorized = 401,
  notFound = 404,
}

export const FALLBACK_STATUS_TRIGGERS: RetryableHttpStatus[] = Object.keys(RetryableHttpStatus).map(key => RetryableHttpStatus[key])

export interface RetryableHttpStatusError {
  response: {
    status: RetryableHttpStatus
    data?: {
      metadata?: {
        trace_id: string
      }
    }
  }
}

export type RetryableError = UnauthorizedGlobalSessionResponseError | RetryableHttpStatusError

export function isRetryableError(err: any): err is RetryableError {
  return err instanceof UnauthorizedGlobalSessionResponseError ||
    (err.response?.status && FALLBACK_STATUS_TRIGGERS.includes(err.response.status))
}

export function traceIdFromRetryableError(err: RetryableError): string {
  return err instanceof UnauthorizedGlobalSessionResponseError ? err.traceId : err.response?.data?.metadata?.trace_id
}
