import authUrls from '../lib/auth-urls'
import Component from '../lib/component'
import { flushCache } from '../lib/service-cache'
import { SessionStateManager, SignOutReason } from '../session-state'
import { SignInButton } from './sign-in-button'

const { templateFn } = require('./sign-out-button.ejs')

export class SignOutButton extends SignInButton {

  private _signOutUrl: string
  public get signOutUrl(): string {
    return this._signOutUrl
  }

  public get eventBindings() {
    if (!this.element) {
      return []
    }
    return [
      this.element, 'click', '[nb-event="ProfileMenu:Click:SignOut"]', this.onSignOut,
    ]
  }

  constructor(parent: Component) {
    super(parent)
    this.templateFn = templateFn
  }

  protected updateUrlProperties(): void {
    super.updateUrlProperties()
    this._signOutUrl = authUrls.getSignOutUrl()
  }

  private onSignOut(e) {
    flushCache()
    if (SessionStateManager.instance) {
      SessionStateManager.instance.auth.signOut(SignOutReason.userSignOut)

      // SessionStateManager handles redirecting
      // e.preventDefault()  todo: temporary to allow logouts even when 3rd party cookies blocked and you're not on the subdomain
      return false
    }
  }
}
