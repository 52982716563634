// This utility is a collection of methods for handling native events without jquery.
// As a rule, we should try to avoid canceling events unless absolutely necessary to
// allow other components to react when things happen. This also includes utilities
// for marking and checking what components have handled an event.

let guid = 1
let passiveCapture = true // default to just capture boolean for browsers that don't support event listener options
let nonPassiveCapture = true // default to just capture boolean for browsers that don't support event listener options

// expand event listener options for browsers that support them
try {
  const fn = function(){}
  const options = { passive: true }
  window.addEventListener("test", fn, options)
  window.removeEventListener("test", fn, options)
  passiveCapture = { passive: true, capture: true }
  nonPassiveCapture = { passive: false, capture: true }
} catch(e) {}

// Marks an event as handled by a particular component

function handleEvent(event, handler) {
  const e = event
  if (!e || !handler) return
  const data = e.data = e.data || {} // setup data object
  const handlers = data.handlers = data.handlers || [] // setup handlers
  data.handled = true
  data.handlers.push(handler)
}

// Returns the hanlders already associated with this event

function getHandlers(event) {
  const e = event
  return e && e.data && e.data.handlers || []
}

// Returns true/false if the event was already handled by one of the given hanlders (accepts single handler or an array)

function eventHandledBy(event, handlers) {
  const eventHandlers = getHandlers(event)
  const checkHandlers = [].concat(handlers || []) // cast as an array
  for (const handler of checkHandlers) {
    if (eventHandlers.indexOf(handler) !== -1) return true
  }
  return false
}

// Helper function for binding events to raw dom elements with optional delegation using the following signature:
//
//   EventUtility.bindEvents('on', [
//     this.element, 'click', this.doClick,
//     this.element, 'click', '.childSelector', this.doChildClick
//   ])

function bindEvents(onOrOff, bindings) {
  let args, delegated
  const events = [].concat(bindings || [])
  const enable = onOrOff === 'on'
  while (events.length > 2) {
    delegated = typeof events[3] === 'function'
    args = events.splice(0, delegated ? 4 : 3)
    off.apply(undefined, args) // remove old bindings
    if (enable) on.apply(undefined, args) // then re-apply
  }
}

// Binds an event to an element with optional delegation in a way that it can be removed easily

function on(element, type, selectorOrFn, fn, capture) {
  const el = element
  const selector = (typeof selectorOrFn === 'string') ? selectorOrFn : undefined
  const fn1 = (selector ? fn : selectorOrFn)
  const fn2 = selector ? delegated(el, selector, fn1) : wrap(fn1)
  const eventBindings = el.seEventBindings = el.seEventBindings || []
  eventBindings.push({ type: type, handler: fn2, guid: (fn1.guid = fn1.guid || guid++), selector: selector })
  el.addEventListener(type, fn2, capture || false)
}

// Uninds an event from an element while handling delegated events

function off(element, type, selectorOrFn, fn) {
  const el = element
  const events = el.seEventBindings || []
  const selector = (typeof selectorOrFn === 'string') ? selectorOrFn : undefined
  const fn1 = selector ? fn : selectorOrFn
  const matches = events.filter(event => event.type === type && event.guid === fn1.guid && event.selector === selector)
  matches.forEach(event => el.removeEventListener(type, event.handler))
}

// A private helper function for managing delegated events

function delegated(element, selector, fn) {
  return function(event) {
    if (matches(element, event, selector)) return fn.apply(undefined, arguments)
  }
}

// A private helper function to find out if a delegated event binding matches the given event

function matches(element, event, selector) {
  let target
  const targets = [].concat(getTargets(event))
  while (targets.length) {
    target = targets.pop()
    if (target.matches && target.matches(selector)) {
      event.data.delegatedTarget = target
      return true
    }
  }
  return false
}

// A private helper function to map the dom tree once so we can eficiently compare for any events in the chain

function getTargets(event) {
  const data = event.data = event.data || {}
  let target = event.target
  if (!data.seTargets) {
    data.seTargets = []
    do { data.seTargets.unshift(target) } while (target = target.parentNode)
  }
  return data.seTargets
}

// A private helper function for managing wrapping events

function wrap(fn) {
  return function() {
    return fn.apply(undefined, arguments)
  }
}

// Some keyboard events (arrows, space) need to be canceled to prevent unwanted scrolling.
// Do not cancel click events. Insted mark them as handeld with the EventUtility.handleEvent().

function cancelEvent(e) {
  e.preventDefault()
  e.stopPropagation()
}

export {
  bindEvents,
  cancelEvent,
  eventHandledBy,
  getHandlers,
  handleEvent,
  nonPassiveCapture,
  off,
  on,
  passiveCapture,
}
