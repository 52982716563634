// this is a little verbose, but required because subclassing native classes like Error doesn't work as expected in
// TypeScript due to how subclassing classes get transpiled

export interface UnauthorizedGlobalSessionResponseError extends Error {
  traceId: string
}

export interface UnauthorizedGlobalSessionResponseErrorCtor {
  new(traceId: string): UnauthorizedGlobalSessionResponseError
}

const UnauthorizedGlobalSessionResponseErrorImpl = (() => {
  function UnauthorizedGlobalSessionResponseErrorImpl(this: any, traceId: string): UnauthorizedGlobalSessionResponseError {
    Error.call(this)
    this.message = 'User is not authenticated'
    this.traceId = traceId
    return this
  }
  UnauthorizedGlobalSessionResponseErrorImpl.prototype = Object.create(Error.prototype)
  return UnauthorizedGlobalSessionResponseErrorImpl
})();

export const UnauthorizedGlobalSessionResponseError: UnauthorizedGlobalSessionResponseErrorCtor =
  // `as unknown as` required due to UnauthorizedGlobalSessionResponseErrorImpl not actually being a class
  UnauthorizedGlobalSessionResponseErrorImpl as unknown as UnauthorizedGlobalSessionResponseErrorCtor
