import { Observable } from 'rxjs'
import { filter, map, pairwise, share, startWith, switchMapTo, take, tap } from 'rxjs/operators'

import { CrossDomainStorageClient, LocalStorageKey } from '../cross-domain'

import { AuthenticationService } from './authentication.service'
import {
  AuthenticatedGlobalSessionState,
  GlobalSessionState,
  ReceivedState,
  UnauthenticatedGlobalSessionState,
} from './global-session-state'
import { PingReason } from './ping-reason'

export class SessionStateService extends Observable<ReceivedState<GlobalSessionState>> {

  private static initSessionStart(state$: Observable<GlobalSessionState>): Observable<AuthenticatedGlobalSessionState> {
    return state$.pipe(
      startWith(undefined as GlobalSessionState),
      pairwise(),
      filter(([prev, next]) => !AuthenticationService.isAuthenticated(prev) && AuthenticationService.isAuthenticated(next)),
      map(([, next]) => next as AuthenticatedGlobalSessionState),
      share(),
    )
  }

  private static initSessionStartWhileSitting$(state$: Observable<GlobalSessionState>): Observable<AuthenticatedGlobalSessionState> {
    return state$.pipe(
      startWith(undefined as GlobalSessionState),
      pairwise(),
      filter(([prev, next]) => {
        console.log('[SessionStateService]:initSessionStartWhileSitting$ observable on what the hech prev is: ', (prev != undefined && !AuthenticationService.isAuthenticated(prev) && AuthenticationService.isAuthenticated(next)), prev);
        return prev != undefined && !AuthenticationService.isAuthenticated(prev) && AuthenticationService.isAuthenticated(next)
      }),
      map(([, next]) => next as AuthenticatedGlobalSessionState),
      share(),
    )
  }

  private static initSessionEnd(
    sessionStart$: Observable<GlobalSessionState>,
    state$: Observable<GlobalSessionState>,
  ): Observable<UnauthenticatedGlobalSessionState> {
    return sessionStart$.pipe(
      switchMapTo(
        // each time a sessionStart$ is emitted, start looking for the first invalid state
        state$.pipe(
          filter(state => !AuthenticationService.isAuthenticated(state)),
          take(1),
        ) as Observable<UnauthenticatedGlobalSessionState>,
      ),
      share(),
    )
  }

  public readonly sessionStart$: Observable<AuthenticatedGlobalSessionState>
  public readonly sessionAuthenticatedNonReload$:  Observable<AuthenticatedGlobalSessionState>
  public readonly sessionEnd$: Observable<UnauthenticatedGlobalSessionState>

  public get currentSessionState(): GlobalSessionState {
    return this.storageClient.getItem<GlobalSessionState>(LocalStorageKey.globalSessionState)
  }

  public get currentTraceId(): string {
    return this.currentSessionState?.trace_id
  }

  private readonly source$: Observable<GlobalSessionState>

  constructor(private storageClient: CrossDomainStorageClient) {
    super(o => this.source$.subscribe(o))
    this.source$ = this.storageClient.observeKey<GlobalSessionState>(LocalStorageKey.globalSessionState).pipe(
      startWith(undefined as GlobalSessionState),
      pairwise(),
      filter(([prev, next]) => {
        if (prev && !prev.trace_id) {
          console.warn('prev with no trace_id', prev, next)
        }
        if (!next?.prev_trace_id) {
          console.warn('next with no prev_trace_id', next)
        }
        const isReplacement = prev?.trace_id === next?.trace_id
        const isAllowed =
          isReplacement ||       // trace IDs match, likely due to an expired session object being replaced with an UnauthenticatedGlobalSessionState
          !prev ||               // "next" is the first state emitted (due to using startWith(undefined) and pairwise())
          !prev.trace_id ||      // nothing to compare against next.prev_trace_id, let it through to be safe
          next?.prev_trace_id?.startsWith(PingReason.initial) || // always allow updates from "initial" requests
          !next?.prev_trace_id || // nothing to compare against prev.trace_id, let it through to be safe
          prev.trace_id === next.prev_trace_id // ideal scenario
        if (!isAllowed) {
          console.warn('filtering out of order state update', { prev, next })
        }
        return isAllowed
      }),
      map(([,next]) => next),
      tap(console.log.bind(console, '[se-bar SessionStateService] onState')),
      share(),
    )
    this.sessionStart$ = SessionStateService.initSessionStart(this)
    this.sessionAuthenticatedNonReload$ = SessionStateService.initSessionStartWhileSitting$(this)
    this.sessionEnd$ = SessionStateService.initSessionEnd(this.sessionStart$, this)
  }

  public next(state: GlobalSessionState): void {
    Object.assign(state, {
      received_at: Date.now(),
    })
    this.storageClient.setItem(LocalStorageKey.globalSessionState, state)
  }

}
