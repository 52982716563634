export enum EnvironmentName {
  production = 'production',
  staging = 'staging',
  development = 'development',
  test = 'test'
}

export interface Environment {
  current: EnvironmentName
}

export const ENV: Environment = {
  // @ts-ignore
  current: process.env.NODE_ENV
}
