import IndicatorDot from './indicator-dot'
import messagesService from '../services/messages'

class MessagesIndicatorDot extends IndicatorDot {

  init() {
    messagesService.on('unreadCount', this.update)
  }

  update(unreadCount) {
    if (this.unreadCount === unreadCount) return
    this.unreadCount = unreadCount
    this.updateStatus(this.getStatus(unreadCount))
  }

  getStatus(count = 0) {
    if (count > 99) return '99+'
    if (count === 0) return false
    return count
  }
}

export default MessagesIndicatorDot
