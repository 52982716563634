// COMPONENT CONSTRUCTOR OPTIONS
//
// icon: string
// color: string (i.e. #FF0000)

import Component from '../lib/component'
import bell from '../icons/bell.svg'
import chat from '../icons/chat.svg'
import chevronDown from '../icons/chevron-down.svg'
import chevronLeft from '../icons/chevron-left.svg'
import chevronRight from '../icons/chevron-right.svg'
import envelope from '../icons/envelope.svg'
import flag from '../icons/flag.svg'
import menu from '../icons/menu.svg'
import shield from '../icons/shield.svg'
import sportAmericanFootball from '../icons/sport-american-football.svg'
import sportBadminton from '../icons/sport-badminton.svg'
import sportBaseball from '../icons/sport-baseball.svg'
import sportBasketball from '../icons/sport-basketball.svg'
import sportBiathlon from '../icons/sport-biathlon.svg'
import sportCrossCountrySkiing from '../icons/sport-cross-country-skiing.svg'
import sportCurling from '../icons/sport-curling.svg'
import sportFieldHockey from '../icons/sport-field-hockey.svg'
import sportFreeski from '../icons/sport-freeski.svg'
import sportGolf from '../icons/sport-golf.svg'
import sportGymnastics from '../icons/sport-gymnastics.svg'
import sportIceHockey from '../icons/sport-ice-hockey.svg'
import sportInlineSkating from '../icons/sport-inline-skating.svg'
import sportLacrosse from '../icons/sport-lacrosse.svg'
import sportRugby from '../icons/sport-rugby.svg'
import sportSkating from '../icons/sport-skating.svg'
import sportSkiing from '../icons/sport-skiing.svg'
import sportSkiJumping from '../icons/sport-ski-jumping.svg'
import sportSnowboarding from '../icons/sport-snowboarding.svg'
import sportSoccer from '../icons/sport-soccer.svg'
import sportSoftball from '../icons/sport-softball.svg'
import sportSpeedSkating from '../icons/sport-speed-skating.svg'
import sportSwimming from '../icons/sport-swimming.svg'
import sportTennis from '../icons/sport-tennis.svg'
import sportVolleyball from '../icons/sport-volleyball.svg'
import sportWrestling from '../icons/sport-wrestling.svg'
import spotSafety from '../icons/spot-safety.svg'

const ICONS = {
  bell,
  chat,
  chevronDown,
  chevronLeft,
  chevronRight,
  envelope,
  flag,
  menu,
  shield,
  sportAmericanFootball,
  sportBadminton,
  sportBaseball,
  sportBasketball,
  sportBiathlon,
  sportCrossCountrySkiing,
  sportCurling,
  sportFieldHockey,
  sportFreeski,
  sportGolf,
  sportGymnastics,
  sportIceHockey,
  sportInlineSkating,
  sportLacrosse,
  sportRugby,
  sportSkating,
  sportSkiing,
  sportSkiJumping,
  sportSnowboarding,
  sportSoccer,
  sportSoftball,
  sportSpeedSkating,
  sportSwimming,
  sportTennis,
  sportVolleyball,
  sportWrestling,
  spotSafety,
}

class Icon extends Component {
  constructor(options) {
    super(options)
    this.templateFn = () => ICONS[options.icon]
  }

  render() {
    const markup = super.render()
    let attrs = `class="${this.class}"`
    if (this.color) attrs += ` style="color: ${this.color}"`
    else if (this.style) attrs += ` style="${this.style}"`
    return markup.replace(/(<[a-z-]+)/, `$1 ${attrs}`)
  }
}

export default Icon


