/*

re-rendering is still messed up. I think it might work better if we use a flow like

1. parent renders markup the first time using <%- $ctrl.child %>
2. child.toString() returns a placeholder, like <span id="component-123"></span>
3. the init event chain runs, and the child element replaces the placeholder with child, rendered markup
4. child updates itself later, and replaces content/copies attrs if in the dom, or replaces a new placeholder in the parent if not

*/
import { SubscriptionTracker } from '../services'

import * as uiHelpers from './ui-helpers'
import { bindEvents } from './event-utility'
import { bindMethods, createElementFromHTML, findElement, getAllProperties, omit, replaceElement } from './util'
import eventEmitter from 'event-emitter'
import config from './config'

import { APP_HQ, APP_TEAM_CENTER } from './constants'

const IGNORED_OPTIONS = ['parent', 'elementId']
const doc = document
const win = window
const html = doc.documentElement

let componentId = 0
let root

function passThrough(ret) { return ret }

function copyAttrs(source, dest) {
  const attributes = Array.from(source.attributes)
  let attr
  while (attr = attributes.pop()) {
    dest.setAttribute(attr.nodeName, attr.nodeValue)
  }
}

// CUSTOM INSTANCE METHODS (define in your component as needed)
//
// get eventBindings() {
//   return [
//     this.$someElement, 'eventType', 'delegatedSelector', this.someHandler,
//     this.$anotherElement, 'eventType', 'delegatedSelector', this.anotherHandler,
//   ]
// }
//
// get findInParent() {
//   return true // force element lookup to start at the parent element
// }
//
// get appendTo() {
//   return 'body' // selector/element where rendired content will be inserted
// }
//
// afterLoad() {
//   // Returning a promise will delay the init chain for sub components
// }
//
// afterRender() {
//   // Returning a promise will delay the init chain for sub components
// }
//
// afterUpdate() {
//   // This runs last and will not delay anything (for now)
// }
//
// init() {
//   // Returning a promise will delay the init chain for sub components
// }
//
// load() {
//   // can return a promise
// }

class Component extends SubscriptionTracker {
  constructor(options) {
    super()
    root = root || this // exposes nginbar instance as component.root
    options = options || {}
    this._id = ++componentId
    this._hasExternalElement = !!options.elementId
    this._elementId = options.elementId || `nb-component-${this._id}`
    this._elementAttr = `data-nb-component-${this._id}`
    this.selector = `.${this._elementId}`
    this._elements = {}
    this._components = {}
    this.loaded = false
    this.updated = false
    this.doc = doc
    this.win = win
    this.html = html

    bindMethods(this)
    this._setOptions(omit(options, ...IGNORED_OPTIONS))
    this._setParent(options.parent)

    // bind this to an existing element in the dom
    if (options.elementId) this._init()
  }

  get root() { return root }

  get referrerConfigParams() {
    var qs = `se-bar-theme=${this.root.theme}&se-bar-app=${this.root.app}`
    switch(this.root.app) {
      case APP_HQ:
        return qs + (this.root.orgId ? `&se-bar-org-id=${this.root.orgId}` : '')
      case APP_TEAM_CENTER:
        return qs + (this.root.teamId ? `&se-bar-team-id=${this.root.teamId}` : '')
      default:
        return qs
    }
  }

  // PROTECTED METHODS (don't modify these)

  render() {
    if (typeof this.templateFn !== 'function') {
      throw new Error('templateFn must be a function')
    }
    // IMPORTANT: the names of any top-level properties added here must also be included in the "destructuredLocals"
    //            array in ejs-compiler-loader.js
    const obj = Object.assign({ $ctrl: this, config }, uiHelpers)
    return this.templateFn(obj)
  }

  // used to render markup into a parent template by just calling the component reference in ejs
  toString() {
    return `<span id="${this._elementId}"></span>`
  }

  // Render a DOM element attribute that will link back to the component.$name ater init.
  // Make suer you use the `<%-` template binding, or this will not work as expected.
  trackElementAs(name) {
    this._elements[name] = null // will get set to dom element before init
    return ` ${this._elementAttr}="${name}" ` // extra space just to be safe
  }

  // PRIVATE METHODS (don't use these)

  _afterRender() {
    return Promise.resolve()
      .then(this._setElements)
      .then(this._bindEvents)
      .then(this._emitFn('render'))
      .then(this.afterRender || passThrough)
  }

  _afterUpdate() {
    return Promise.resolve()
      .then(() => this.updated = true)
      .then(this._emitFn('update'))
      .then(this.afterUpdate || passThrough)
  }

  _destroy() {
    this.dispose()
    return Promise.resolve()
      .then(this._unbindEvents)
      .then(this._unsetParent)
      .then(this._emitFn('destroy'))
  }

  _init() {
    const init = Promise.resolve()
      .then(this.init || passThrough)
      .then(() => this._inited = true)
      .then(this._emitFn('init'))

    if (this._parent) return init
    return init.then(this._update)
  }

  // Render in loading state, fetch data if needed, and re-render in loaded state
  _update() {
    if (!this.loaded) this.loaded = !this.load // only load the first time

    if (this.loaded) {
      return Promise.resolve()
        .then(this._render)
        .then(this._afterRender)
        .then(this._afterUpdate)
    }

    return Promise.resolve()
      .then(this._render)
      .then(this._afterRender)
      .then(this.load)
      .then(() => this.loaded = true)
      .then(this._emitFn('load'))
      .then(this.afterLoad || passThrough)
      .then(this._render)
      .then(this._afterRender)
      .then(this._afterUpdate)
  }

  _render() {
    const markup = this.render()
    const appendEl = this.appendTo ? findElement(this.appendTo) : null
    const parentEl = this._parent && this._parent.element || null
    const rootEl = appendEl || parentEl || html
    const external = this._hasExternalElement
    let renderedEl
    let append
    let targetEl = findElement(this.element, rootEl)

    if (!targetEl) targetEl = findElement(`#${this._elementId}`, rootEl)
    if (!targetEl && appendEl) {
      append = true
      targetEl = appendEl
    }
    if (!targetEl) return

    if (external) {
      targetEl.innerHTML = markup
      this.element = targetEl
    } else {
      this.element = createElementFromHTML(markup)
      if (append) targetEl.appendChild(this.element)
      else replaceElement(targetEl, this.element)
    }

    // note: SVG elements in IE11 do not have a classList
    if (this.element.classList) {
      this.element.classList.add(this._elementId)
    } else {
      const classAttr = this.element.getAttribute('class')
      this.element.setAttribute('class', `${classAttr} ${this._elementId}`)
    }

    return this.element
  }

  // find and set sub elements on $ctrl that were tracked using $ctrl.trackElementAs('$name')
  _setElements() {
    for (const name in this._elements) {
      this[name] = this._findElement(`[${this._elementAttr}="${name}"]`)
    }
  }

  _setOptions(options) {
    const existing = getAllProperties(this)
    for (const prop in options) {
      if (!existing.includes(prop)) this[prop] = options[prop]
    }
  }

  _setParent(parent) {
    if (!parent) return

    this._parent = parent
    parent._trackComponent(this)
    parent.on('init', this._init)
    parent.on('update', this._update)
    parent.on('destroy', this._destroy)
    if (parent._inited && !this._inited) this._init()
  }

  _trackComponent(component) {
    if (component instanceof Component) this._components[component._id] = component
  }

  _unsetParent() {
    const parent = this._parent
    if (!parent) return

    parent._untrackComponent(this)
    parent.off('init', this._init)
    parent.off('update', this._update)
    parent.off('destroy', this._destroy)
  }

  _untrackComponent(component) {
    if (component instanceof Component) delete this._components[component._id]
  }


  // ELEMENTS

  _findPlaceholder() {
    // return this._parent ? this._
  }

  _findElement(selector) {
    if (this.element && !this.findInParent) return this.element.matches(selector) ? this.element : findElement(selector, this.element)
    if (this._parent) return this._parent._findElement(selector)
    return null
  }

  // EVENTS

  _bindEvents() {
    bindEvents('on', this._rawEventBindings())
  }

  _unbindEvents() {
    bindEvents('off', this._rawEventBindings())
  }

  _rawEventBindings() {
    return this.eventBindings || []
  }

  _emitFn(e) {
    return (ret) => {
      this.emit(e, this)
      return ret
    }
  }

}

eventEmitter(Component.prototype)

export { Component }
export default Component
