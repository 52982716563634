import { Observable } from 'rxjs'
import { filter, map, share, shareReplay } from 'rxjs/operators'

import { SubscriptionTracker } from '../services'

import { CrossDomainClient } from './cross-domain-client'
import { CrossDomainClientMessageType } from './cross-domain-client-message-type'
import { CrossDomainHostMessageType } from './cross-domain-host-message-type'
import { CrossDomainClientMessage } from './cross-domain-message'
import { CrossDomainStorageEvent } from './cross-domain-storage-event'
import { LocalStorageKey } from './local-storage-key'

function isStorageUpdateMessage(message: CrossDomainClientMessage<any>): message is CrossDomainClientMessage<CrossDomainStorageEvent> {
  return message.type === CrossDomainClientMessageType.storageUpdate
}

/**
 * A service that acts as a client for synchronization of data across domains.
 *
 * Upon initialization, receives the "current" set of values for each {@link LocalStorageKey} from the host and stores
 * it in a per-instance, in-memory cache. It receives updates from the host each time a key is changed by an external
 * document, and updates the values in the cache.
 */
export class CrossDomainStorageClient extends SubscriptionTracker {

  public readonly updates$: Observable<CrossDomainStorageEvent>

  private readonly items: { [key: string]: any } = {}

  constructor(private xdClient: CrossDomainClient) {
    super()
    this.updates$ = this.xdClient.messages$.pipe(
      filter(isStorageUpdateMessage),
      map(event => event.data),
      shareReplay(),
    )

    this.trackSubscription(this.xdClient.messages$, this.onMessage)
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.storageRegisterClient)
  }

  /**
   * Publishes an update using the specified {@param key} and {@param value}, and updates the key in the local in-memory
   * cache.
   */
  public setItem(key: LocalStorageKey, value: any): void {
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.storageSetItem, {
      key,
      value,
    })
    this.items[key] = value
  }

  /**
   * Gets the current value of the specified {@param key} from the local in-memory cache.
   */
  public getItem<T = any>(key: LocalStorageKey): T {
    return this.items[key]
  }

  /**
   * Filters {@link updates$} by the specified {@param key}, and emits the corresponding value for each event.
   * @param key
   * @param filterUndefined when `true`, removes `undefined` values from the stream (defaults to `false`)
   */
  public observeKey<TValue>(key: LocalStorageKey, filterUndefined: boolean = false): Observable<TValue> {
    return this.updates$.pipe(
      filter(event => event.key === key && (!filterUndefined || typeof event.value !== 'undefined')),
      map(event => event.value),
      share(),
    )
  }

  private onMessage(message: CrossDomainClientMessage<any>): void {
    switch(message.type) {
      case CrossDomainClientMessageType.storageUpdate: this.onStorageUpdate(message)
    }
  }

  private onStorageUpdate(message: CrossDomainClientMessage<CrossDomainStorageEvent>): void {
    // console.log('[se-bar CrossDomainStorageClient] onStorageUpdate', message)
    this.items[message.data.key] = message.data.value
  }

}

