import NginBar, { NBID, SETAG } from './components/ngin-bar'
import { SeBarBootstrapper } from './components/se-bar-bootstrapper'
import { LocationService } from './lib/location'
import request from './lib/request'
import { loadAllResources } from './lib/resource-loader'
import { beginCacheSession } from './lib/service-cache'
import { hasTouch, initializeCSSVars } from './lib/ui-helpers'
import messagesService from './services/messages'
import chatService from './services/chat'
import userPreferencesService from './services/user-preferences'
import { GlobalSessionEndpoint, SessionStateManager } from './session-state'

const bootstrapper = new SeBarBootstrapper(
  document,
  localStorage,
  NginBar,
  SessionStateManager,
  new GlobalSessionEndpoint(new LocationService(), request),
  userPreferencesService,
  messagesService,
  chatService,
  beginCacheSession,
  loadAllResources,
  initializeCSSVars,
)

bootstrapper.run(NBID, SETAG, hasTouch).catch(err => console.log('[se-bar] error bootstrapping SE Bar', err))
