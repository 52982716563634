// FIXME: this would be good to pull out into a separate library

const SIMPLE_REQUEST_METHODS = [
  'HEAD',
  'GET',
  'POST',
]

// see https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests
const SIMPLE_REQUEST_HEADERS = {
  'accept': true,
  'accept-language': true,
  'content-language': true,
  'content-type': [
    'application/x-www-form-urlencoded',
    'multipart/form-data',
    'text/plain',
  ],
  'dpr': true,
  'downlink': true,
  'save-data': true,
  'viewport-width': true,
  'width': true,
}

function validateRequestMethod(method: string): void {
  if (!SIMPLE_REQUEST_METHODS.includes(method.toLocaleUpperCase())) {
    throw new Error(`Cannot simplify a '${method}' request`)
  }
}

export interface Headers {
  [key: string]: string
}

/**
 * Validates and simplifies headers for making a "simple" HTTP request - a request that will not trigger a CORS
 * preflight request.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Simple_requests for details on CORS and simple requests.
 *
 * @param method The HTTP method used for the request (GET, POST, etc)
 * @param headers A hash object of headers to be used for the request
 * @param allowInvalidHeaderValues If `false`, will throw an error if a header with an invalid value is encountered (
 *        for example, ContentType: application/json). If `true` invalid header values are silently removed from the
 *        resulting object. Defaults to `false`.
 */
export function simplifyHeaders(method: string, headers: Headers, allowInvalidHeaderValues: boolean = false): Headers {

  validateRequestMethod(method)

  if (!headers) {
    return headers
  }

  return Object.keys(headers).reduce((result, headerName) => {
    const headerAllowed = SIMPLE_REQUEST_HEADERS[headerName.toLocaleLowerCase()]
    if (!headerAllowed) {
      return result
    }

    const headerValue = headers[headerName]

    if (headerAllowed === true) {
      result[headerName] = headerValue
      return result
    }

    if (Array.isArray(headerAllowed)) {
      if (headerAllowed.includes(headerValue)) {
        result[headerName] = headerValue
        return result
      }
      if (!allowInvalidHeaderValues) {
        throw new Error(`Header '${headerName}: ${headerValue} cannot be simplified`)
      }
    }
    return result
  }, {})
}
