import t from '../lib/i18n'
import MenuButton from './menu-button'
import ChatDropdown from './chat-dropdown';
import ChatIndicatorDot from './chat-indicator-dot'

class ChatButton extends MenuButton {
  constructor(options) {
    super({
      parent: options.parent,
      dropdownClass: ChatDropdown,
      label: t('CHAT.tooltip'),
      gaLabel: 'ChatMenu',
      icon: 'chat',
      indicatorClass: ChatIndicatorDot
    })
  }
}

export default ChatButton
