import {CACHE_KEY_SESSION_TIMEOUT, CACHE_VALUE_SESSION_TIMEOUT_ENABLED} from "../components/se-bar-bootstrapper";

const buildConfig = {
  urls: _URLS,
  firebase: _FIREBASE,
  crossDomain: _CROSSDOMAIN,

  // Note: these settings override those passed by the client app. Using custom auth URLs is not supported.
  // Use the "single logout" pattern to implement app-specific sign out logic
  // https://sportngin.atlassian.net/wiki/spaces/KEYS/pages/929726586/Single+Logout
  signOutUrl: `${_URLS.userService}/oauth/sign_out`,
  signInUrl: `${_URLS.userService}/users/sign_in`,
  signInUserReturnToOverride: undefined,
  signOutUserReturnToOverride: undefined,
  signInReloadOverrideUrl: undefined
}

const config = Object.assign(buildConfig, window.nginBarConfig, window.seBarConfig)

export default config
