import IndicatorDot from './indicator-dot'
import notificationsService from '../services/notifications'

class NotificationsIndicatorDot extends IndicatorDot {

  init() {
    notificationsService.getNotificationsPollOn(this.update)
  }

  update(notifications) {
    this.updateStatus(this.getStatus(notifications))
  }

  getStatus(notifications) {
    const count = notifications.length
    if (!count) return false
    if (count > 99) return '99+'
    return count
  }
}

export default NotificationsIndicatorDot
