import statusOk from '../icons/status-ok.svg'
import statusInfo from '../icons/status-info.svg'
import statusError from '../icons/status-error.svg'
import {
  ACTION_STATUS_APPLICATION_ERROR,
  ACTION_STATUS_CONNECTION_ERROR,
  ACTION_STATUS_OK,
  ACTION_STATUS_RESPONSE_ERROR,
} from '../lib/constants'

export default {
  [ACTION_STATUS_APPLICATION_ERROR]: statusInfo,
  [ACTION_STATUS_CONNECTION_ERROR]: statusError,
  [ACTION_STATUS_OK]: statusOk,
  [ACTION_STATUS_RESPONSE_ERROR]: statusInfo,
}
