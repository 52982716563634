import { merge, Observable, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'

import { CrossDomainStorageClient, LocalStorageKey } from '../cross-domain'

import { UserActivity, userActivityCollector } from './user-activity-collector'

export class UserActivityService extends Observable<UserActivity> {

  public get lastUserActivity(): UserActivity {
    return this.storageClient.getItem<UserActivity>(LocalStorageKey.lastUserActivity)
  }

  private readonly localUserActivity$ = userActivityCollector(
    'load',

    // mouse/touch
    'click',
    'mousemove',

    // keyboard
    'keypress',

    // scrolling
    'scroll',
    'wheel',
  )
  private readonly manualUserActivity$$ = new Subject<UserActivity>()

  constructor(private storageClient: CrossDomainStorageClient) {
    super(o => {
      const externalSource$ = this.storageClient.observeKey<UserActivity>(LocalStorageKey.lastUserActivity, true)
      const localSource$ = this.localUserActivity$.pipe(
        tap(userActivity => this.storageClient.setItem(LocalStorageKey.lastUserActivity, userActivity)),
      )

      const source$ = merge(localSource$, externalSource$, this.manualUserActivity$$)
      return source$.subscribe(o)
    })
  }

  public next(userActivity: UserActivity) {
    console.log('[se-bar UserActivityService] next', userActivity)
    this.storageClient.setItem(LocalStorageKey.lastUserActivity, userActivity)
    this.manualUserActivity$$.next(userActivity)
  }

}
