export const templateFn = function anonymous(locals, escapeFn, include, rethrow
) {
"use strict";
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm);
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<div class=\"nb-menu-item--mobile-hide\">\n  <span\n    aria-label=\"<%= $ctrl.label %>\"\n    aria-expanded=\"<%= $ctrl.active || false %>\"\n    class=\"nb-menu-button\"\n    id=\"<%= $ctrl._elementId %>\"\n    role=\"button\"\n    tabindex=\"0\"\n    <%- $ctrl.tooltip.target %>\n  >\n    <%- $ctrl.indicatorDot %>\n    <%- $ctrl.iconSvg || $ctrl.avatar || '' %>\n  </span>\n  <%- $ctrl.dropdown %>\n</div>\n\n"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  var __locals = (locals || {}),
$ctrl = __locals.$ctrl,
  config = __locals.config,
  formatDate = __locals.formatDate,
  hasTouch = __locals.hasTouch,
  initializeCSSVars = __locals.initializeCSSVars,
  initialsFromFullName = __locals.initialsFromFullName,
  multiToggleFactory = __locals.multiToggleFactory,
  t = __locals.t,
  htmlWebpackPlugin = __locals.htmlWebpackPlugin;
    ; __append("<div class=\"nb-menu-item--mobile-hide\">\n  <span\n    aria-label=\"")
    ; __line = 3
    ; __append(escapeFn( $ctrl.label ))
    ; __append("\"\n    aria-expanded=\"")
    ; __line = 4
    ; __append(escapeFn( $ctrl.active || false ))
    ; __append("\"\n    class=\"nb-menu-button\"\n    id=\"")
    ; __line = 6
    ; __append(escapeFn( $ctrl._elementId ))
    ; __append("\"\n    role=\"button\"\n    tabindex=\"0\"\n    ")
    ; __line = 9
    ; __append( $ctrl.tooltip.target )
    ; __append("\n  >\n    ")
    ; __line = 11
    ; __append( $ctrl.indicatorDot )
    ; __append("\n    ")
    ; __line = 12
    ; __append( $ctrl.iconSvg || $ctrl.avatar || '' )
    ; __append("\n  </span>\n  ")
    ; __line = 14
    ; __append( $ctrl.dropdown )
    ; __append("\n</div>\n\n")
    ; __line = 17
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}; export default templateFn