import { sha224 } from 'js-sha256'

const getCookie = (name) => {
  var value = '; ' + document.cookie
  var parts = value.split('; ' + name + '=')
  if (parts.length == 2) {
    return parts
      .pop()
      .split(';')
      .shift()
  }
}

const getSessionToken = () => getCookie('sportngin_session')

const buildSessionHash = () => {
  const sessionToken = getSessionToken()
  if (sessionToken) { return sha224(sessionToken) }
}

export { getSessionToken, buildSessionHash }
