import { camelCase } from './data-helpers'

const DEFAULT_KEY = 'flag'
const ALIASES = {
  'flag': [
    'sport-other',
    'sport-archery',
    'sport-ball-hockey',
    'sport-bandy',
    'sport-bobsled',
    'sport-bowling',
    'sport-cheerleading',
    'sport-climbing',
    'sport-cricket',
    'sport-cross-country',
    'sport-cycling',
    'sport-dance',
    'sport-diving',
    'sport-fencing',
    'sport-roller-hockey',
    'sport-sled-hockey',
    'sport-judo',
    'sport-karate',
    'sport-taekwondo',
    'sport-para-biathlon',
    'sport-rifle-shooting',
    'sport-trap-shooting',
    'sport-short-track-skating',
    'sport-snowshoe',
    'sport-para-alpine-skiing',
    'sport-para-cross-country-skiing',
    'sport-para-snowboarding',
    'sport-running',
    'sport-track-and-field',
    'sport-water-polo',
    'sport-paintball',
    'sport-luge',
    'sport-pentathlon',
    'sport-racquetball',
    'sport-rowing',
    'sport-sailing',
    'sport-skeleton',
    'sport-squash'
  ],
  'sport-curling': ['sport-wheelchair-curling'],
  'sport-ski-jumping': ['sport-nordic-combined'],
  'sport-skiing': ['sport-alpine-skiing'],
  'sport-freeski': ['sport-freestyle-skiing'],
  'sport-skating': ['sport-ice-dance', 'sport-ice-skating', 'sport-figure-skating'],
  'sport-soccer': ['sport-futsal']
}

const KEYS_BY_ALIAS = {}
for (const original in ALIASES) {
  ALIASES[original].forEach(alias => KEYS_BY_ALIAS[alias] = original)
}

function getSportIcon(sportKey) {
  if (!sportKey) return DEFAULT_KEY
  const alias = camelCase(`sport-${sportKey}`)
  return KEYS_BY_ALIAS[alias] || alias
}

export {
  getSportIcon
}
