/**
 * A mutex request is canceled when a mutex client determines it no longer needs a lock after it has been requested, but
 * before it has been granted
 */
export class MutexRequestCanceledError extends Error {

  public static isMutexCanceledError(err: Error): err is MutexRequestCanceledError {
    return err && ((err as any).mutexCanceled)
  }

  public readonly mutexCanceled = true

  constructor(public readonly reason?: string) {
    super(`Mutex request canceled by requester${reason ? `: ${reason}` : ''}`)
  }
}
