import { merge, Observable, of } from 'rxjs'
import { distinctUntilChanged, filter, map, pairwise, share, startWith, switchMap, take } from 'rxjs/operators'

import Component from '../../lib/component'
import { AuthenticatedGlobalSessionState, SessionStateManager, SignOutReason, UserState } from '../../session-state'

import { SessionInactivityWarning } from './session-inactivity-warning'

const { templateFn } = require('./session-timeout.ejs')

function isTransitionToActive([prev, current]: [UserState, UserState]): boolean {
  return current === UserState.active && prev !== UserState.active
}

export class SessionTimeout extends Component {

  public inactivityWarning: SessionInactivityWarning

  public readonly inactivityWarning$: Observable<SessionInactivityWarning>

  constructor(private readonly sessionState: SessionStateManager) {
    super()
    this.templateFn = templateFn

    console.log('[se-bar SessionTimeout] ctr')

    this.inactivityWarning$ = this.initCountdownState().pipe(
      distinctUntilChanged((a, b) => {
        if ((!a && b) || (a && !b)) {
          return false
        }
        if (!a && !b) {
          return true
        }
        // we only care about the expiration - prevent emitting due to new objects from pings if the session expiration
        // doesn't actually change - this decreases thrashing from needlessly replacing the entire component
        return a.authenticated === b.authenticated &&
          a.expires_at === b.expires_at
      }),
      map(state => state ? new SessionInactivityWarning(state, this) : undefined),
      share(),
    )

    this.trackSubscription(this.inactivityWarning$, this.onInactivityWarning)
  }

  private initCountdownState(): Observable<AuthenticatedGlobalSessionState> {
    const nextActiveTransition$ = this.sessionState.userState$.pipe(
      startWith(undefined as UserState),
      pairwise(),
      filter(isTransitionToActive),
      map(() => undefined),
      take(1),
    )

    return this.sessionState.userInactivityAlert$.pipe(
      switchMap(idleState => merge(of(idleState), nextActiveTransition$))
    )
  }

  private onInactivityWarning(inactivityWarning: SessionInactivityWarning): void {
    this.removeInactivityWarning()
    this.initInactivityWarning(inactivityWarning)
    this._update()
  }

  private removeInactivityWarning(): void {
    if (this.inactivityWarning) {
      this.inactivityWarning._destroy()
      this.inactivityWarning = undefined
    }
  }

  private initInactivityWarning(inactivityWarning: SessionInactivityWarning): void {
    if (inactivityWarning) {
      console.log('[se-bar SessionTimeout] detected idle state, showing countdown')
      inactivityWarning.extend$.subscribe(() => this.onExtend())
      inactivityWarning.signOut$.subscribe(() => this.onSignOut())
    } else {
      console.log('[se-bar SessionTimeout] detected non-idle state, hiding countdown')
    }
    this.inactivityWarning = inactivityWarning
  }

  private onExtend(): void {
    this.sessionState.extendSession()
    this.removeInactivityWarning()
    this._update()
  }

  private onSignOut(): void {
    this.sessionState.auth.signOut(SignOutReason.userSignOut)
    this.removeInactivityWarning()
    this._update()
  }

}
