import Dropdown from './dropdown'
import MessagesList from './messages-list'
import MessagesFooter from  './messages-footer'
import messagesService from '../services/messages'
import templateFn from './messages-dropdown.ejs'

class MessagesDropdown extends Dropdown {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
  }

  init() {
    super.init()
    this.messagesList = new MessagesList({ parent: this })
    this.messagesFooter = new MessagesFooter({ parent: this })
  }

  open() {
    messagesService.on('unreadCount', this.updateMessages)
    messagesService.getMessages(false)
    super.open()
  }

  close() {
    messagesService.off('unreadCount', this.updateMessages)
    super.close()
  }

  updateMessages(unreadCount, fromPagination) {
    if (!fromPagination) messagesService.getMessages(true)
  }
}

export default MessagesDropdown
