import configInstance, { SeBarConfig } from './config'
import locationInstance, { LocationService } from './location'
import {CACHE_KEY_SESSION_TIMEOUT, CACHE_VALUE_SESSION_TIMEOUT_ENABLED} from '../components/se-bar-bootstrapper';

export class AuthUrls {

  constructor(
    private readonly config: SeBarConfig,
    private readonly location: LocationService,
  ) {}

  public getSignInUrl() {
    const baseSignInUrl = this.config.signInUrl
    const separator = baseSignInUrl.includes('?') ? '&' : '?'
    const userReturnTo = this.config.signInUserReturnToOverride || (this.location.queryParam('user_return_to') || this.location.hrefFiltered)

    return `${baseSignInUrl}${separator}user_return_to=${encodeURIComponent(userReturnTo)}`
  }

  public getSignOutUrl(redirectUri?: string): string {
    const baseSignOutUrl = this.config.signOutUrl
    const separator = baseSignOutUrl.includes('?') ? '&' : '?'
    const encodedRedirectUri = encodeURIComponent(this.config.signOutUserReturnToOverride || redirectUri || this.getSignInUrl())
    return `${baseSignOutUrl}${separator}redirect_uri=${encodedRedirectUri}`
  }

  public getSignInReloadUrl(): string {
    const reloadOverrideUrl = this.config.signInReloadOverrideUrl
    const returnTo = this.location.queryParam('user_return_to')
    const reloadUrl = reloadOverrideUrl || returnTo
    return reloadUrl
  }

}

export default new AuthUrls(configInstance, locationInstance)
