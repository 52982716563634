import { AxiosRequestConfig } from 'axios'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map, retryWhen, share, switchMap } from 'rxjs/operators'

import config from '../lib/config'
import { LocationService } from '../lib/location'
import { SeBarRequest } from '../lib/request'
import { UserProfile } from '../lib/user-profile'

import { AuthenticationService } from './authentication.service'
import { isRetryableError, traceIdFromRetryableError } from './global-session-retryable'
import {
  AuthenticatedGlobalSessionResponse,
  AuthenticatedGlobalSessionState,
  mapAuthenticatedResponseToState,
} from './global-session-state'
import { UnauthorizedGlobalSessionResponseError } from './invalid-global-session-response-error'

type GlobalSessionHttpMethod = 'get' | 'post'
const GLOBAL_SESSION_PATH = 'global_session/me'
const MAX_RETRIES = 2

export interface SessionActivityData {
  lastActivity: number
}

function serialize(obj: any, start: string = ''): string {
  return Object.keys(obj).reduce((result, key) => {
    const value = obj[key]
    if (typeof value === 'object') {
      throw new Error('complex objects are not supported')
    }
    if (result.length) {
      result += '&'
    }
    return result + `${key}=${encodeURIComponent(value.toString())}`
  }, start)
}

function retryableStrategy(maxRetries: number) {
  // retryWhen gives us an Observable of the cumulative errors thrown from the source Observable
  return (errors$: Observable<Error>) => errors$.pipe(
    switchMap((err, index) => {
      if (isRetryableError(err) && index < maxRetries) {
        // returning a non-throwing Observable tells retryWhen to allow retrying (resubscribing to the source)
        return of(err)
      }
      // returning a throwing Observable causes the error the "bubble up" to the source observable and prevents retrying
      return throwError(err)
    }),
  )
}

export class GlobalSessionEndpoint {

  private get primaryAPIBaseUrl(): string {
    return config.urls.seApi
  }

  private get alternateAPIBaseUrl(): string {
    if (this.location.isVanityDomain) {
      return `https://${this.location.origin}/apigateway`
    }
    return config.urls.apiProxy
  }

  private get explicitFallbackUrl() {
    return config.apiFallbackUrl
  }

  constructor(private location: LocationService, private request: SeBarRequest) {}

  /**
   * Attempts to fetch the current "global session" object for the current user from the SE API.
   *
   * If the initial request fails, the request will be retried up to {@link MAX_RETRIES} times.
   *
   * If a {@link GlobalSessionEndpoint} call has not previously resulting a 200 response from the API, it will attempt
   * to "discover" a working base URL using {@link alternateAPIBaseUrl} and {@link explicitFallbackUrl}. Each baseUrl
   * gets its own set of retries (initial attempt + {@link MAX_RETRIES}).
   */
  public fetch(): Observable<AuthenticatedGlobalSessionState & UserProfile> {
    return this.doRequest('get').pipe(
      map(mapAuthenticatedResponseToState),
      share(),
    )
  }
  /**
   * Attempts to update the current "global session" object for the current user from the SE API based on the last known
   * user activity provided in {@param lastActivityFromExpires}.
   *
   * If the initial request fails, the request will be retried up to {@link MAX_RETRIES} times.
   *
   * If a {@link GlobalSessionEndpoint} call has not previously resulting a 200 response from the API, it will attempt
   * to "discover" a working base URL using {@link alternateAPIBaseUrl} and {@link explicitFallbackUrl}. Each baseUrl
   * gets its own set of retries (initial attempt + {@link MAX_RETRIES}).
   */
  public ping(lastActivityFromExpires?: number): Observable<AuthenticatedGlobalSessionState> {
    const data = {
      last_activity_from_expires: lastActivityFromExpires,
    }
    return this.doRequest('post', data).pipe(
      map(mapAuthenticatedResponseToState),
      share(),
    )
  }

  private doRequest(method: GlobalSessionHttpMethod, data?: any): Observable<AuthenticatedGlobalSessionResponse> {
    if (this.location.isVanityDomain && !config.loggedIn) {
      // On an ngin site but not logged into site
      return throwError(new UnauthorizedGlobalSessionResponseError('auto'))
    }

    const request$ = this.doRequestWithRetries(method, data)

    return request$.pipe(
      catchError(err => {
        // if (isRetryableError(err)) {
          // user is not authenticated
          console.log('[se-bar] GlobalSessionEndpoint.doRequest.  Interpreting request errors as unauthenticated', err)
          return throwError(new UnauthorizedGlobalSessionResponseError(traceIdFromRetryableError(err)))
        // }

        // rethrow other errors
        // return throwError(err)
      }),
    )
  }

  private doRequestWithRetries(method: GlobalSessionHttpMethod, data?: SessionActivityData): Observable<AuthenticatedGlobalSessionResponse> {
    return this.doRequestWithBaseUrlDiscovery(method, data).pipe(
      catchError((err) => this.doRequestWithBaseUrlDiscovery(method, data))
    )
  }

  private doRequestWithBaseUrlDiscovery(method: GlobalSessionHttpMethod, data?: SessionActivityData): Observable<AuthenticatedGlobalSessionResponse> {
    return this.createRequest(method, this.primaryAPIBaseUrl, data).pipe(
      catchError((err) => {
        return this.createRequest(method, this.alternateAPIBaseUrl, data)
      })
    )
  }

  private attemptBaseUrlRequest(method: GlobalSessionHttpMethod, baseUrl: string, data?: SessionActivityData): Observable<AuthenticatedGlobalSessionResponse> {
    return this.createRequest(method, baseUrl, data).pipe(
      retryWhen(retryableStrategy(MAX_RETRIES)),
    )
  }

  private conditionalDiscovery(discoveryFn: (err?: Error) => Observable<AuthenticatedGlobalSessionResponse>): (err: Error) => Observable<AuthenticatedGlobalSessionResponse> {
    return (err: Error) => {
      if (this.request.defaults.baseURL) {
        return throwError(err)
      }
      return discoveryFn(err)
    }
  }

  private getMakeRequestFn(method: GlobalSessionHttpMethod, baseUrl: string, data?: SessionActivityData): () => Promise<AuthenticatedGlobalSessionResponse> {
    const config: AxiosRequestConfig = {
      headers: {
        Accept: 'application/json',
      },
      simpleHeaders: true,
    }
    if (baseUrl) {
      config.baseURL = baseUrl
    }
    if (data) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }

    /** `as unknown as () => Promise<GlobalSessionState>` because the response gets unwrapped by
     * {@link responseSuccessInterceptor} in ../lib/request.ts, but Axios's typings don't allow that to be
     * represented correctly
     */
    return (method === 'get' ?
        () => this.request[method]<AuthenticatedGlobalSessionResponse>(GLOBAL_SESSION_PATH, config) :
        () => this.request[method]<AuthenticatedGlobalSessionResponse>(GLOBAL_SESSION_PATH, serialize(data), config)
    ) as unknown as () => Promise<AuthenticatedGlobalSessionResponse>
  }

  private createRequest(method: GlobalSessionHttpMethod, baseUrl: string, data?: SessionActivityData): Observable<AuthenticatedGlobalSessionResponse> {
    const makeRequest = this.getMakeRequestFn(method, baseUrl, data)
    /**
     * The Observable constructor is used instead of rxjs's {@link from} when converting from Axios's promise to enable
     * retry logic - resubscribing to an observable created with `from(promise)` would not cause the request to be
     * issued again, as it would still be based on the result of the original promise. Creating the promise in the
     * "subscribe" handler for the Observable allows the request to be reissued by re-subscribing.
     */
    return new Observable<AuthenticatedGlobalSessionResponse>(o => {

      console.log('[se-bar] GlobalSessionEndpoint.createRequest', { baseUrl })
      makeRequest()
        .then(res => {
          const isAuthenticatedPost = method === 'post' && AuthenticationService.isAuthenticated(res)
          // GET responses do not include timing properties used by isAuthenticated
          const isAuthenticatedGet = method === 'get' && !!res?.id

          // if we do get a response but the session is null or empty try again for refresh
          if (!isAuthenticatedPost && !isAuthenticatedGet) {
            console.warn('not authenticated', method, res)
            return Promise.reject(new UnauthorizedGlobalSessionResponseError(res?.trace_id))
          }

          this.request.setRequestBaseUrl(baseUrl)
          return res
        })
        .then(res => {
          o.next(res)
          o.complete()
        })
        .catch(err => o.error(err))
    })
  }
}
