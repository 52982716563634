import Dropdown from './dropdown'
import Avatar from './avatar'
import Icon from './icon'
import MessagesMenuItem from './messages-menu-item'
import MessagesList from './messages-list'
import ChatMenuItem from './chat-menu-item'
import ChatList from './chat-list'
import MessagesFooter from './messages-footer'
import NotificationsList from './notifications-list'
import NotificationsMenuItem from './notifications-menu-item'
import config from '../lib/config'
import { customAvatar } from '../lib/service-helpers'
import templateFn from './profile-dropdown.ejs'
import { resetScroll } from '../lib/scroll-utility'
import messagesService from '../services/messages'
import chatService from '../services/chat'
import { SignOutButton } from './sign-out-button'
import { ENV } from '../lib/env'

const SUBMENU_ACTIVE = 'nb-submenu-active' // applied to #ngin-bar

class ProfileDropdown extends Dropdown {
  constructor(options) {
    super(options)

    this.templateFn = templateFn
    this.config = config
    this.user = this.profile = options.profile
    this.fullName = this.user.first_name + ' ' + this.user.last_name
    this.profileUrl = config.urls.profile
    this.settingsUrl = config.urls.accountSettings
    this.dropdownLinks = config.profileDropdownLinks || []
    this.ccpaLinks = this.buildCcpaLinks()
    this.coreUrl = config.coreUrl || `${config.urls.nginUserDashboard}/core/`
    this.signOutButton = new SignOutButton(this)
  }

  get eventBindings() {
    const bindings = [
      this.element, 'click', '.js-open-messages', this.openMessages,
      this.element, 'click', '.js-open-chat', this.openChat,
      this.element, 'click', '.js-open-notifications', this.openNotifications,
      this.element, 'click', '.nb-submenu__back', this.closeSubmenu,
      this.element, 'click', '.js-open-elevio', this.openEvelio
    ]
    return super.eventBindings.concat(bindings).concat(this.dropdownLinkBindings)
  }

  get dropdownLinkBindings() {
    const bindings = []
    this.dropdownLinks.forEach((link, i) => {
      for (const event in link.events) {
        const handler = link.events[event]
        if (!handler) continue
        bindings.push(this.element, event, `[nb-link-index="${i}"]`, (e) => handler(e, e.data.delegatedTarget))
      }
    })
    return bindings
  }

  buildCcpaLinks() {
    if (!this.config.ccpa) return []

    if (['test', 'development', 'staging'].includes(ENV.current)) {
      return [
        {
          label: 'PROFILE.privacy_policy',
          href: 'https://www.nbcuniversal.com/privacy?intake=SportsEngine'
        },
        {
          label: "PROFILE.do_not_sell",
          href: 'https://stage.nbcuniversal.com/privacy/notrtoo'
        },
        {
          label: "PROFILE.ca_notice",
          href: 'http://dev.nbcuniversal.com/privacy/california-consumer-privacy-act'
        }
      ]
    } else {
      return [
        {
          label: 'PROFILE.privacy_policy',
          href: 'https://www.nbcuniversal.com/privacy?intake=SportsEngine'
        },
        {
          label: "PROFILE.do_not_sell",
          href: 'https://www.nbcuniversal.com/privacy/notrtoo?intake=SportsEngine'
        },
        {
          label: "PROFILE.ca_notice",
          href: 'https://www.nbcuniversal.com/privacy/california-consumer-privacy-act?intake=SportsEngine'
        }
      ]
    }
  }

  init() {
    super.init()
    this.messagesMenuItem = new MessagesMenuItem({ parent: this })
    this.messagesList = new MessagesList({ parent: this })
    this.messagesFooter = new MessagesFooter({ parent: this })
    this.messagesBackIcon = new Icon({ parent: this, icon: 'chevronLeft', class: 'nb-submenu__icon' })
    this.chatMenuItem = new ChatMenuItem({ parent: this })
    this.chatList = new ChatList({ parent: this })
    this.chatBackIcon = new Icon({ parent: this, icon: 'chevronLeft', class: 'nb-submenu__icon' })
    this.notificationsMenuItem = new NotificationsMenuItem({ parent: this })
    this.notificationsList = new NotificationsList({ parent: this })
    this.notificationsBackIcon = new Icon({ parent: this, icon: 'chevronLeft', class: 'nb-submenu__icon' })
    this.fullName = this.profile.first_name + ' ' + this.profile.last_name
    this.profileAvatar = new Avatar({
      parent: this,
      type: 'person',
      size: 500,
      identity: this.fullName,
      imgUrl: customAvatar(this.profile.profile_image_url)
    })
  }

  afterRender() {
    if (window._elev) this.showHelpLink()
    else document.addEventListener('elevio:loaded',() => this.showHelpLink())
  }

  showHelpLink() {
    this.$helpLink.style.display = ''
  }

  openMessages() {
    resetScroll(this.messagesList.selector)
    this.root.element.classList.add(SUBMENU_ACTIVE)
    this.$messagesMenu.classList.add(SUBMENU_ACTIVE)
    messagesService.on('unreadCount', this.updateMessages)
    messagesService.getMessages(false)
  }

  openChat() {
    resetScroll(this.chatList.selector)
    this.root.element.classList.add(SUBMENU_ACTIVE)
    this.$chatMenu.classList.add(SUBMENU_ACTIVE)
    chatService.on('unreadCount', this.updateChats)
    chatService.getChats(false)
  }

  openNotifications() {
    resetScroll(this.notificationsList.selector)
    this.root.element.classList.add(SUBMENU_ACTIVE)
    this.$notificationsMenu.classList.add(SUBMENU_ACTIVE)
  }

  closeSubmenu() {
    this.root.element.classList.remove(SUBMENU_ACTIVE)
    this.$messagesMenu.classList.remove(SUBMENU_ACTIVE)
    this.$chatMenu.classList.remove(SUBMENU_ACTIVE)
    this.$notificationsMenu.classList.remove(SUBMENU_ACTIVE)
    messagesService.off('unreadCount', this.updateMessages)
  }

  updateMessages(unreadCount, fromPagination) {
    if (!fromPagination) messagesService.getMessages(true)
  }

  updateChats(unreadCount) {
    chatService.getChats(true)
  }

  close() {
    super.close()
    this.closeSubmenu()
  }

  openEvelio() {
    window._elev.open()
  }

}

export default ProfileDropdown
