import { ENV } from './env'

// FIXME: pull out into a common module - effectively duplicated with getAllowedOriginPatterns
const DOMAIN_SUFFIXES = {
  'development': ['.sportngin.com.dev', '.sportsengine.com.dev'],
  'staging': ['.stage.ngin-staging.com', '.sestage.us', '.sportngin.com.dev', '.sportsengine.com.dev'],
  'production': ['.sportngin.com', '.sportsengine.com'],
}
const DOMAIN_PREFIXES = {
  'development': [],
  'staging': ['https://s3.amazonaws.com/sportngin-snap-staging/ui_themes'],
  'production': ['https://s3.amazonaws.com/sportngin-snap-production/ui_themes']
}

export interface QueryParams {
  [key: string]: string
}

// IMPORTANT: this is an intentionally naive implementation - additional logic will be necessary in order to
// handle any form of array values, either by using duplicate query param keys, array notation (e.g. foo[]=bar or
// foo[0]=bar), or comma-delimited values
function parseQueryString(query: string): QueryParams {
  return (query.startsWith('?') ? query.substring(1) : query)
    .split('&')
    .reduce((result: QueryParams, pair: string) => {
      const [key, value] = pair.split('=')
      result[key] = decodeURIComponent(value)
      return result
    }, {})
}

export class LocationService {

  public get href(): string {
    return this.window.location.href
  }

  public get origin(): string {
    return this.window.location?.hostname || ''
  }

  public get hostWithScheme(): string {
    let toRet = ''
    if (this.window.location) {
      toRet = this.window.location.protocol + '//' + this.window.location.hostname
    }
    return toRet
  }

  public get hrefFiltered(): string {
    let thisLocationFiltered = this.hostWithScheme
    // todo: there is absolutely more to check for here and more logic even to determine if its even appropriate.
    thisLocationFiltered = this.href  // wrap if url does not contain(,..).  experimenting at this point
    return thisLocationFiltered
  }

  public get baseURI(): string {
    return this.window.document.baseURI
  }

  public get isSEDomain(): boolean {
    if (!ENV.current || !DOMAIN_SUFFIXES[ENV.current]) {
      return false
    }
    return (DOMAIN_SUFFIXES[ENV.current].some((domainSuffix) => this.origin.endsWith(domainSuffix)) ||
      DOMAIN_PREFIXES[ENV.current].some(domainPrefix => this.origin.startsWith(domainPrefix)) )
  }

  public get isVanityDomain(): boolean {
    return !this.isSEDomain
  }

  private readonly window: Window

  constructor(_window: Window = window) {
    this.window = _window
  }

  public navigateTo(url: string): void {
    this.window.location.href = url
  }

  public getQueryParams(): QueryParams {
    return parseQueryString(this.window.location.search)
  }

  public queryParam(key: string): string {
    return this.getQueryParams()[key]
  }

}

export default new LocationService()
