import Component from '../lib/component'
import messagesService from '../services/messages'
import templateFn from './messages-list.ejs'

class MessagesList extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    messagesService.on('messages', this.update)
  }

  update(messages) {
    this.messages = messages
    if (this.element) this._update()
  }

}

export default MessagesList
