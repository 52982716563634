import Component from '../lib/component'
import Icon from './icon'
import templateFn from './back-button.ejs'


class BackButton extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
  }

  init() {
    this.chevronLeft = new Icon({ parent: this, icon: 'chevronLeft', class: 'nb-back__chevron-icon' })
  }

}

export default BackButton
