import request from '../lib/request'
import Service from '../lib/service'
import config from '../lib/config'

class MfaOnboardingService extends Service {
  constructor() {
    super()
    this.baseUrl = ''
    this.promise = null
  }

  showOnboardingPrompt() {
    return request
      .get('v3/mfa_onboarding/show_onboarding_prompt', {
        baseURL: this.derivedBaseUrl,
        simpleHeaders: true, // avoid a preflight request
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.log('[se-bar] Show show_onboarding_prompt error', error)
        return {}
      })
  }
}

// since services are singletons we instantiate here
const mfaOnboardingService = new MfaOnboardingService()

export default mfaOnboardingService
