import { interval, Observable } from 'rxjs'
import { map, shareReplay, take, tap } from 'rxjs/operators'

import { SubscriptionTracker } from '../services'

import { CrossDomainClient } from './cross-domain-client'
import { CrossDomainHostMessageType } from './cross-domain-host-message-type'
import { CrossDomainMutexEvent } from './cross-domain-mutex-event'
import { MutexClientRequest } from './mutex-client-request'

const AUTO_EXTEND_BUFFER_MS = 250

export class MutexLock extends SubscriptionTracker {

  private readonly autoExtend$: Observable<void>

  constructor(
    private xdClient: CrossDomainClient,
    public readonly request: MutexClientRequest,
    public readonly event: CrossDomainMutexEvent,
  ) {
    super()

    if (this.request.options.autoExtend) {
      this.autoExtend$ = this.initAutoExtend(this.request.options.autoExtend)
      this.trackSubscription(this.autoExtend$, this.extend)
    }
  }

  public extend(): void {
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.mutexExtend, this.request.mutexData)
  }

  public release(): void {
    this.dispose()
  }

  public dispose(): void {
    super.dispose()
    console.log('[se-bar MutexLock] releasing', this.request.mutexData)
    this.xdClient.postMessageToHost(CrossDomainHostMessageType.mutexRelease, this.request.mutexData)
  }

  private initAutoExtend(autoExtend: true | number): Observable<void> {
    return interval(this.request.releaseTimeout - AUTO_EXTEND_BUFFER_MS).pipe(
      take(autoExtend === true ? Infinity : autoExtend),
      map(() => {}),
      shareReplay(), // only do all this once regardless of the number of subscriptions
    )
  }
}
