import MenuButton from './menu-button'
import t from '../lib/i18n'
import switcherService from '../services/switcher'

import Icon from './icon'
import SwitcherDropdown from './switcher-dropdown'

import templateFn from './switcher-button.ejs'

class SwitcherButton extends MenuButton {
  constructor(options) {
    super({
      parent: options.parent,
      label: t('SWITCHER.tooltip'),
      gaLabel: 'ContextSwitcher',
      dropdownClass: SwitcherDropdown,
    })
    this.templateFn = templateFn
    this.buttonText = switcherService.buttonText
    this.iconSvg = new Icon({ parent: this, icon: 'chevronDown', class: 'nb-switcher-button__icon' })
  }

  load() {
    return switcherService
      .getOrgMenu()
      .then(() => {
        this.buttonText = switcherService.buttonText
        this.dropdown.updateMenus(switcherService.menus)
      })
  }

}

export default SwitcherButton
