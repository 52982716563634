import MenuButton from './menu-button'
import t from '../lib/i18n'
import notificationsService from '../services/notifications'
import NotificationsDropdown from './notifications-dropdown'
import NotificationsIndicatorDot from './notifications-indicator-dot'

class NotificationsButton extends MenuButton {
  constructor(options) {
    super({
      parent: options.parent,
      dropdownClass: NotificationsDropdown,
      label: t('NOTIFICATIONS.tooltip'),
      gaLabel: 'NotificationsMenu',
      icon: 'bell',
      indicatorClass: NotificationsIndicatorDot
    })
  }
}

export default NotificationsButton
