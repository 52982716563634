// This is based heavily of the se-ui-tooltip component

import Component from '../lib/component'
import { eventHandledBy, handleEvent } from '../lib/event-utility'

import templateFn from './tooltip.ejs'

const VISIBLE_CLASS = `nb-tooltip--visible`
const HIDE_DELAY = 200 // ms
const SHOW_DELAY = 1500 // ms
const HAS_TOUCH = 'ontouchstart' in document.documentElement

class Tooltip extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.visible = false
    this._disabled = false
  }

  get disabled() {
    return this._disabled
  }

  set disabled(bool) {
    if (bool && !this.disabled) this.hide()
    this._disabled = bool
  }

  get findInParent() {
    return true // forces element finding to start at the parent element (so we can find the $target)
  }

  get appendTo() {
    return 'body' // selector/element where rendired content will be inserted
  }

  get eventBindings() {
    const bindings = HAS_TOUCH ? this.touchBindings : this.mouseBindings
    return bindings.concat([
      this.doc, 'scroll', this.hide,
      this.doc, 'resize', this.hide
    ])
  }

  get touchBindings() {
    return [
      this.$target, 'touchstart', this.toggle,
      this.doc, 'touchstart', this.hide,
    ]
  }

  get mouseBindings() {
    return [
      this.$target, 'mouseenter', this.showSoon,
      this.$target, 'mouseleave', this.hideSoon,
      this.$target, 'mousedown', this.toggle,
      this.element, 'mouseenter', this.show,
      this.element, 'mouseleave', this.hideSoon,
      this.element, 'mousedown', this.hide,
      this.doc, 'mousedown', this.hide
    ]
  }

  toggle(e) {
    handleEvent(e, this)
    this.visible = !this.visible
    if (this.visible) this.show()
    else this.hide()
  }

  show() {
    if (this.disabled) return
    this.visible = true
    this.clearTimeouts()
    this.position()
  }

  showSoon() {
    if (!this.visible) this.showTimeout = setTimeout(this.show, SHOW_DELAY)
    else this.clearTimeouts()
  }

  hide(e) {
    if (eventHandledBy(e, this)) return
    this.visible = false
    this.clearTimeouts()
    this.element.classList.remove(VISIBLE_CLASS)
    this.hideTimeout = setTimeout(() => this.element.style.display = 'none', 200) // matches css transition duration
  }

  hideSoon() {
    if (this.visible) this.hideTimeout = setTimeout(this.hide, HIDE_DELAY)
    else this.clearTimeouts()
  }

  clearTimeouts() {
    clearTimeout(this.showTimeout)
    clearTimeout(this.hideTimeout)
  }

  position() {

    this.element.style.display = '' // reset display value so we can get dimensions

    const doc = window.document
    const docEl = doc.documentElement || doc.body
    const docHeight = docEl.clientHeight
    const docWidth = docEl.clientWidth

    const target = this.$target.getBoundingClientRect()

    // width/height are calculated from left/right because it's more accurate
    const targetLeft = target.left
    const targetTop = target.top
    const targetBottom = target.bottom
    const targetWidth = target.right - targetLeft
    const targetHeight = targetBottom - targetTop

    // width/height are calculated from left/right because it's more accurate
    const tip = this.element.getBoundingClientRect()
    const tipWidth = tip.right - tip.left
    const tipHeight = tip.bottom - tip.top

    // potential coordinates based on the size of the tip and position of the target
    const center = targetLeft + targetWidth / 2
    const right = center + tipWidth / 2
    const bottom = targetBottom + tipHeight

    // position above by default
    let left = center - tipWidth / 2
    let top = targetTop - tipHeight

    const leftBleed = left < 0 ? 0 - left : 0
    const rightBleed = right > docWidth ? right - docWidth : 0
    const topBleed = top < 0 ? 0 - top : 0
    const bottomBleed = bottom > docHeight ? bottom - docHeight : 0

    // adjust horizontal positioning if the tip is outside the window bounds
    if (leftBleed) left = 0
    else if (rightBleed) left = docWidth - tipWidth

    // adjust vertical positioning if the tip is outside the window bounds
    if (topBleed) {
      if (!bottomBleed) {
        top = targetBottom
      }
      else {
        if (topBleed < bottomBleed) {
          top = 0
        }
        else {
          top = docHeight - tipHeight
        }
      }
    }

    this.element.classList.add(VISIBLE_CLASS)
    this.element.style.top = `${top}px`
    this.element.style.left = `${left}px`
  }

  // Renders a tag attribute in the parent
  get target() {
    return this.trackElementAs('$target')
  }
}

export default Tooltip
