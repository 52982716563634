import { merge, Observable, of, timer } from 'rxjs'
import { distinctUntilChanged, map, share, startWith, switchMap } from 'rxjs/operators'

import { AuthenticationService } from './authentication.service'
import { AuthenticatedGlobalSessionState, GlobalSessionState } from './global-session-state'
import { SessionStateService } from './session-state.service'
import { UserState } from './user-state'

/**
 * Represents the current activity state of a user.
 *
 * This reduces the most recent value from {@link SessionStateService} to a single {@link UserState} value, and emits
 * updates when the resulting {@link UserState} value changes - when receiving new {@link GlobalSessionState} data from
 * {@link SessionStateService}, when the {@link AuthenticatedGlobalSessionState.alert_after} time has passed, and when
 * the {@link AuthenticatedGlobalSessionState.expires_at} time has passed.
 */
export class UserStateService extends Observable<UserState> {

  private static determineState(state: GlobalSessionState): UserState {
    if (!AuthenticationService.isAuthenticated(state)) {
      return UserState.expired
    }
    if (state.alert_after < Date.now()) {
      return UserState.idle
    }
    return UserState.active
  }

  public get currentUserState(): UserState {
    return UserStateService.determineState(this.sessionState$.currentSessionState)
  }

  constructor(
    private sessionState$: SessionStateService,
  ) {
    super(o => {
      const sessionStateWithTrigger$ = this.sessionState$.pipe(
        switchMap(state => {
          const current$ = of(state)

          if (!AuthenticationService.isAuthenticated(state)) {
            return current$
          }

          const now = Date.now()
          const msToExpiration = state.expires_at - now + 1
          const expired$ = timer(msToExpiration).pipe(map(() => state))

          if (state.alert_after < now) {
            // emit immediately, and after (+1) "expires_at"
            return merge(current$, expired$)
          }

          const idle$ = timer(state.alert_after - now + 1).pipe(map(() => state))
          // emit immediately, after (+1) "alert_after", and after (+1) "expires_at"
          return merge(current$, idle$, expired$)
        }),
      )
      const source$ = sessionStateWithTrigger$.pipe(
        map((state) => UserStateService.determineState(state)),
        startWith(UserState.unknown),
        distinctUntilChanged(),
        share(),
      )
      return source$.subscribe(o)
    })
  }

}
