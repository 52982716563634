import request from '../lib/request'
import Service from '../lib/service'
import config from '../lib/config'

export class UserPreferencesService extends Service {
  constructor(preloadedData) {
    super()
    this.cache = {}
    this.promise = null
  }

  setPreferences(data) {
    this.cacheAll({ data: { result: data } })
  }

  clearCache() {
    this.promise = null
    for (var prop in this.cache) {
      if (this.cache.hasOwnProperty(prop)) delete this.cache[prop]
    }
  }

  cacheAll(prefs) {
    this.clearCache()
    prefs.forEach(pref => this.cacheOne(pref))
    return this.cache
  }

  cacheOne(pref) {
    pref = pref.data || pref
    var p = this.cache[pref.key] = pref
    if (p.datatype === 'boolean') p.value = (p.value === 'true' || p.value === true) // converts string values to boolean
    return p
  }

  get(key) {
    if (this.promise) return this.promise
    return this.promise = request.get('user_preferences')
      .then(resp => this.cacheAll(resp))
      .then(prefs => key ? (prefs[key] || {}).value : prefs)
  }

  set(key, value, feedback) {
    var body = { key: key, value: value }
    if (feedback) body.feedback = feedback
    return request.put(URL, body).then(resp => this.cacheOne(resp))
  }
}

const userPreferencesService = new UserPreferencesService()

export default userPreferencesService
