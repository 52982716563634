import { Observable, Subscription } from 'rxjs'

/**
 * A utility class that can be used as either a superclass or
 * mixin (https://www.typescriptlang.org/docs/handbook/mixins.html), that allows RxJS {@link Subscription}s to be
 * tracked internally, and unsubscribed all at once for cleanup by calling the {@link dispose} method.
 */
export class SubscriptionTracker {

  private readonly subscriptions: Subscription[] = []

  public dispose(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
    this.subscriptions.length = 0
  }

  /**
   * Tracks the specified subscription so it is unsubscribed when {@link dispose} is called.
   */
  protected trackSubscription(subscription: Subscription): void
  /**
   * Subscribes to an {@link Observable}, saving the subscription internally to be unsubscribed when {@link dispose}
   * is called.
   * @param source
   * @param nextFn the handler for the "next" callback of the subscription, is automatically bound to the instance
   */
  protected trackSubscription<T>(source: Observable<T>, nextFn?: (value: T) => void)
  protected trackSubscription(sourceOrSubscription: Observable<any> | Subscription, nextFn?: (value: any) => void): void {
    let subscription: Subscription
    if (sourceOrSubscription instanceof Subscription) {
      subscription = sourceOrSubscription
    } else {
      subscription = sourceOrSubscription.subscribe(nextFn ? nextFn.bind(this) : undefined)
    }
    this.subscriptions.push(subscription)
  }

}
