// This module creates a custom Google analytics tracker using the GA api already installed on the page.
// If GA is not installed, it will inject the universal analytics api and use that instead. We also make
// the assumption that if GTM is loading, that analytics will be installed through that instead.

const WIN = window
const DOC = document
const NAMESPACE = 'GoogleAnalyticsObject'
const GA = WIN[NAMESPACE] = WIN[NAMESPACE] || 'ga'
const BASE = 'NginBar'
const GTM_SRC = '//www.googletagmanager.com/gtm.js'
const ANALYTICS_SRC = '//www.google-analytics.com/analytics.js'
const GA_PROJECT_ID = 'UA-821370-90' // sn_frontend GA for now
const TRACKER_NAME = 'NB'
const EVENT_DELAY = 50

class CustomTracker {

  constructor(propertyId) {
    // check to see if ga will load
    var gaLoading = WIN[GA] || DOC.querySelector(`script[src*="${GTM_SRC}"]`)

    // create our custom tracker
    this.ga('create', propertyId, 'auto', TRACKER_NAME)

    // inject the analytics api unless something else mocked the ga function or gtm is loading
    if (!gaLoading) {
      var scr = 'script'
      var script = DOC.createElement(scr)
      var firstScript = DOC.getElementsByTagName(scr)[0]
      script.async = 1
      script.src = ANALYTICS_SRC
      firstScript.parentNode.insertBefore(script, firstScript)
    }
  }

  get ga() {
    if (!WIN[GA]) {
      WIN[GA] = function(){ (WIN[GA].q = WIN[GA].q || []).push(arguments) }
      WIN[GA].l || 1*new Date()
    }
    return WIN[GA]
  }

  // Example: sendEevent('Category', 'Action', 'Label', 123, true)
  sendEvent(category, action, label, value, nonInteraction) {
    WIN[GA](`${TRACKER_NAME}.send`, 'event', `${BASE}.${category}`, action, label, value, nonInteraction)
  }

  sendEventLink(e, category, action, label, value, nonInteraction) {
    const el = e.data && e.data.delegatedTarget || e.currentTarget
    const href = el.getAttribute('href')
    const target = el.getAttribute('target') || '_self'
    const topOrSelf = target == '_top' || target === '_self'
    let preventedByHost = !!e.defaultPrevented
    this.sendEvent(category, action, label, value, nonInteraction)
    // let the normal link click continue if it won't disrupt the ga event sending
    if (!topOrSelf || !href) return
    // otherwise, prevent the event and mimic it's behavior, tracking if the host app also
    // prevents the event so we don't follow the link when we shouldn't
    e.preventDefault()
    e.preventDefault = () => preventedByHost = true
    setTimeout(() => {
      if (preventedByHost) return
      if (topOrSelf) window[target.substring(1)].location = href
      else window.new(href, target)
    }, EVENT_DELAY)
  }
}

export default new CustomTracker(GA_PROJECT_ID)
