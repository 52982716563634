import { Observable } from 'rxjs'

import Component from '../../lib/component'

const { templateFn } = require('./countdown.ejs')

export class Countdown extends Component {

  private _value: string | number
  public get value(): string | number {
    return this._value
  }

  constructor(value$: Observable<string | number>, parent: Component, elementId?: string) {
    super({ parent, elementId })
    this.templateFn = templateFn

    this.trackSubscription(value$, this.onValue)
  }

  private onValue(value: string | number): void {
    this._value = value;
    this._update()
  }
}
