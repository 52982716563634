import { CrossDomainClient, CrossDomainStorageClient, MutexClient } from '../cross-domain'
import authUrlsInstance, { AuthUrls } from '../lib/auth-urls'
import configInstance, { SeBarConfig } from '../lib/config'
import locationInstance, { LocationService } from '../lib/location'
import request from '../lib/request'

import { AuthenticationService } from './authentication.service'
import { GlobalSessionEndpoint } from './global-session.endpoint'
import { PingService } from './ping.service'
import { SessionStateService } from './session-state.service'
import { Timer, TimerConstructor } from './timer'
import { UserActivityService } from './user-activity.service'
import { UserStateService } from './user-state.service'

export type SessionStateManagerDeps = [
  AuthenticationService,
  AuthUrls,
  CrossDomainClient,
  CrossDomainStorageClient,
  LocationService,
  MutexClient,
  GlobalSessionEndpoint,
  PingService,
  SeBarConfig,
  SessionStateService,
  TimerConstructor,
  UserActivityService,
  UserStateService,
]

export type SessionStateManagerConstructorDeps = [
  AuthenticationService,
  AuthUrls,
  LocationService,
  PingService,
  SeBarConfig,
  SessionStateService,
  TimerConstructor,
  UserActivityService,
  UserStateService,
]

/**
 * This is used in place of an actual DI/IoC system to make it a little easier to manage the dependencies required
 * for {@link SessionStateManager}, which is the entry point for all the session timeout functionality.
 *
 * In an actual runtime environment, no dependencies are specified, and the {@link ensureDeps} function takes care of
 * making sure all the dependencies are instantiated.
 *
 * In a test environment, the test is responsible for setting up stubs for the dependencies as passing them as the
 * {@param deps} parameter.
 */
export function ensureDeps(deps: SessionStateManagerDeps): SessionStateManagerConstructorDeps {
  let [
    authService,
    authUrls,
    xdClient,
    storageClient,
    location,
    mutexClient,
    pingEndpoint,
    pingService,
    config,
    sessionState$,
    TimerCtr,
    userActivity$,
    userState$,
  ] = deps || []

  const requiresMutexClient = !pingService
  const requiresStorageClient = !sessionState$ || !userActivity$
  const requiresXDClient = !mutexClient && (requiresMutexClient || requiresStorageClient)
  const requiresPingEndpoint = !pingService

  if (requiresXDClient) {
    xdClient = xdClient || new CrossDomainClient()
  }
  if (requiresStorageClient) {
    storageClient = storageClient || new CrossDomainStorageClient(xdClient)
  }
  if (requiresMutexClient) {
    mutexClient = mutexClient || new MutexClient(xdClient)
  }

  sessionState$ = sessionState$ || new SessionStateService(storageClient)
  TimerCtr = TimerCtr || Timer

  authService = authService || new AuthenticationService(sessionState$)
  authUrls = authUrls || authUrlsInstance
  userActivity$ = userActivity$ || new UserActivityService(storageClient)
  userState$ = userState$ || new UserStateService(sessionState$)
  location = location || locationInstance

  if (requiresPingEndpoint) {
    pingEndpoint = pingEndpoint || new GlobalSessionEndpoint(location, request)
  }

  config = config || configInstance
  pingService = pingService || new PingService(pingEndpoint, mutexClient, userActivity$)

  return [
    authService,
    authUrls,
    location,
    pingService,
    config,
    sessionState$,
    TimerCtr,
    userActivity$,
    userState$,
  ]
}
