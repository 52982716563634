import { bindMethods } from './util'
import eventEmitter from 'event-emitter'
import config from '../lib/config'

const environment = process.env.NODE_ENV

const DOMAIN_SUFFIXES = {
  'development': ['sportngin.com.dev', 'sportsengine.com.dev'],
  'staging': ['stage.ngin-staging.com', 'sestage.us', 'sportngin.com.dev', 'sportsengine.com.dev'],
  'production': ['sportngin.com', 'sportsengine.com']
}

class Service {
  constructor() {
    bindMethods(this)
    this.data = {}
  }

  isSEDomain() {
    if (!environment) { return false }
    return DOMAIN_SUFFIXES[environment].some((domainSuffix) => this.origin.endsWith(domainSuffix))
  }

  isVanityDomain() {
    return !this.isSEDomain()
  }

  isSEApiDomain() {
    this.origin.endsWith(this.primaryAPIBaseUrl.replace('https://se-api.', ''))
  }

  get origin() {
    return (window.location || {}).hostname || ''
  }

  get primaryAPIBaseUrl() {
    return config.urls.seApi
  }

  get alternateAPIBaseUrl() {
    if (this.isVanityDomain()) { return `https://${this.origin}/apigateway` }
    return config.urls.apiProxy
  }

  get explicitFallbackUrl() {
    return config.apiFallbackUrl || ''
  }

  // Determine the base URL to use. There are 3 options...
  // 1. The seApi domain (e.g. - https://se-api.sportsengine.com)
  // 2. A vanity domain suffixed with '/apigateway' (e.g. - https://www.bysafl.com/apigateway)
  // 3. The API proxy domain (i.e. - alternative seAPI domain) (e.g. - https://api.sportngin.com)
  get derivedBaseUrl() {
    return this.isSEApiDomain() ? this.primaryAPIBaseUrl : this.alternateAPIBaseUrl
  }
}

eventEmitter(Service.prototype)

export { Service }
export default Service
