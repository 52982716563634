/*
  Config Example:

  window.nginBarConfig = {
    onInit: function(nginBar) { ... },

    // Including `mobileNav` or a `mobileNavToggle` callback will cause the nginbar to show the mobile menu toggle.
    // When the toggle is clicked, a document `se-left-nav.toggle` event will fire (for the se nav component),
    // and the `nb-mobile-nav-open` class will be toggled on the body element (for legacy nav implementations).

    mobileNav: true,
    mobileNavToggle: function() { ... },
    mobileNavClose: function() { ... }
  }
*/
import '../polyfills'

import config from '../lib/config'
import { t } from '../lib/i18n'
import analytics from '../lib/analytics'
import { multiToggleFactory } from '../lib/ui-helpers'
import Component from '../lib/component'
import BackButton from './back-button'
import ChatButton from './chat-button'
import NotificationsButton from './notifications-button'
import MessagesButton from './messages-button'
import ProfileButton from './profile-button'
import SwitcherButton from './switcher-button'
import Icon from './icon'
import mfaOnboardingService from '../services/mfa-onboarding'
import templateFn from './ngin-bar.ejs'

import { APP_DEFAULT, APP_HQ, APP_LOGO_PREFIX, APP_MY_SE, APP_TEAM_CENTER, APPS } from '../lib/constants'
import { SessionTimeout } from './session-timeout/session-timeout'
import { SignInButton } from './sign-in-button'

const MOBILE_NAV_ENABLED_CLASS = 'nb-mobile-nav-enabled'
const MOBILE_NAV_OPEN_CLASS = 'nb-mobile-nav-open'
const BODY_CLASSES = document.body.classList
const LEFT_NAV_TOGGLE = 'se-left-nav.toggle'
const LEFT_NAV_CLOSE = 'se-left-nav.close'

const THEME_PREFIX = 'nb'
const THEME_LIGHT = 'light'
const THEME_DARK = 'dark'
const THEMES = [THEME_DARK, THEME_LIGHT]
const toggleTheme = multiToggleFactory(THEMES, (state) => `${THEME_PREFIX}-${state}`)

const PAGE_MODE_PREFIX = 'nb-page-mode'
const PAGE_MODE_NORMAL = 'normal'
const PAGE_MODE_STANDALONE = 'standalone'
const PAGE_MODES = [PAGE_MODE_NORMAL, PAGE_MODE_STANDALONE]
const togglePageMode = multiToggleFactory(PAGE_MODES, (state) => `${PAGE_MODE_PREFIX}--${state}`)

const appLogoClassFn = (app) => {
  return app === APP_HQ
    ? `${APP_LOGO_PREFIX}--${app}`
    : APP_LOGO_PREFIX
}

const toggleAppLogo = multiToggleFactory(APPS, appLogoClassFn)
export const NBID = 'ngin-bar'
export const SETAG = 'se-bar'

let instance

export default class NginBar extends Component {

  static get instance() {
    return instance
  }

  constructor(options) {
    super(options)
    if (instance) {
      instance._destroy()
    }
    instance = this
    document.documentElement.classList.add('has-se-bar')
    this.ready = false
    this.templateFn = templateFn
    this.data = {}
    this.profile = options.profile
    this.hasMobileNav = !!(config.mobileNav || config.mobileNavToggle)
    this.mobileNavOpen = false
    this.readyHandlers = [].concat(window.nginBar || window.seBar || [])
    this.sessionState = options.sessionStateManager
    window.nginBar = window.seBar = this
    this.mfaOnboardingService = mfaOnboardingService
  }

  // expose a que interface for adding events
  push(readyHandler) {
    if (this.ready) readyHandler(this)
    else this.readyHandlers.push(readyHandler)
  }

  async init() {
    this.mfaOnboardingIcon = new Icon({ parent: this, icon: 'spotSafety', style: 'display: block; margin-left: auto; margin-right: auto; width: 50%;' })
    if (this.hasMobileNav) this.mobileNavIcon = new Icon({ parent: this, icon: 'menu', class: 'nb-mobile-nav__icon' })
    this.backButton = new BackButton({ parent: this })
    this.signInButton = new SignInButton(this)
    await this.initProfileDependentChildren()
    this.legacySupport()
    this.initConfigEvents()
    this.initSessionState()
  }

  async initProfileDependentChildren() {
    await this.destroyProfileDependentChildren()

    if (this.profile) {
      this.notificationsButton = new NotificationsButton({ parent: this })
      this.messagesButton = new MessagesButton({ parent: this })
      this.profileButton = new ProfileButton({ parent: this, profile: this.profile })
      this.switcherButton = config.hideSwitcher ? '' : new SwitcherButton({ parent: this })
      this.chatButton = new ChatButton({ parent: this })
      const mfaShowOnboardingPromptRequest = await this.mfaOnboardingService.showOnboardingPrompt()
      this.showMfaOnboardingPrompt = mfaShowOnboardingPromptRequest?.show_mfa_onboarding_prompt
    }
  }

  destroyProfileDependentChildren() {
    return Promise.all([
      this._removeChild(this.notificationsButton),
      this._removeChild(this.chatButton),
      this._removeChild(this.profileButton),
      this._removeChild(this.switcherButton),
    ])
  }

  initSessionState() {
    if (this.sessionState) {
      this.sessionTimeout = new SessionTimeout(this.sessionStateManager)
      this.trackSubscription(this.sessionState.sessionState$.sessionStart$, async profile => {
        if (!this.profile || this.profile.id !== profile.id) {
          this.profile = profile
          await this.initProfileDependentChildren()
          this._update()
        }
      })
      this.trackSubscription(this.sessionState.sessionState$.sessionEnd$, async () => {
        this.profile = undefined
        this.destroyProfileDependentChildren()
        this._update()
      })
    }
  }

  async _removeChild(child) {
    if (!child) {
      return
    }
    await child._destroy()
    if (child.element) {
      child.element.remove()
    }
  }

  // Force configuration-based UI updates (thes can all be dynamically changed)
  afterRender() {
    this.app = APPS.includes(this.app) ? this.app : APP_DEFAULT
    this.theme = this.themeIsLight ? THEME_LIGHT : THEME_DARK
    this.pageMode = this.pageMode === PAGE_MODE_STANDALONE ? PAGE_MODE_STANDALONE : PAGE_MODE_NORMAL
  }

  get logoClass() {
    return appLogoClassFn(this.config.app)
  }

  get app() {
    return this.config.app
  }

  set app(val) {
    if (!APPS.includes(val)) val = APP_DEFAULT
    if (!toggleAppLogo(this.$seLogo, val)) return
    this.config.app = val
    this.$seLogo.innerText = this.appName
  }

  get appName() {
    switch (this.config.app) {
      case 'hq': return t('sportsengine_hq')
      default: return t('sportsengine')
    }
  }

  set orgId(orgId) { config.orgId = orgId }
  get orgId() { return this.config.orgId }

  set teamId(teamId) { config.teamId = teamId }
  get teamId() { return this.config.teamId }

  get referrerParam() {
    return `se-ref=${encodeURIComponent(window.location)}`
  }

  get appHomeUrl() {
    switch (this.config.app) {
      case APP_HQ:
        return `${this.config.urls.hq}/${this.orgId}`
      case APP_MY_SE:
        return this.config.urls.mySe
      case APP_TEAM_CENTER:
        return `${this.config.urls.teamCenter}/${this.teamId}`
      default:
        return this.config.urls.se
    }
  }

  get nbMainClass() {
    return `nb-main--${process.env.NODE_ENV}`
  }

  initConfigEvents() {
    var eventConfig = config.on || {}
    for (var e in eventConfig) {
      this.on(e, eventConfig[e])
    }
  }

  // TODO: Replace this with a simple check in the constructor once all light apps pass the theme param
  get themeIsLight() {
    if (this.config.theme) return this.config.theme === THEME_LIGHT
    return document.getElementById('ngin-bar').classList.contains('nb-light')
  }

  get theme() {
    return this.config.theme
  }

  set theme(value) {
    if (!toggleTheme(this.element, value)) return
    this.config.theme = value
  }

  get pageMode() {
    return this.config.pageMode
  }

  set pageMode(value) {
    if (!togglePageMode(this.element, value)) return
    this.config.pageMode = value
  }

  // Remove this once all SE apps are using the new se-ui component
  legacySupport() {
    if (config.onInit) this.on('init', () => config.onInit(this))
    if (this.hasMobileNav) BODY_CLASSES.add(MOBILE_NAV_ENABLED_CLASS)
  }

  get eventBindings() {
    const bindings = [
      this.element, 'click', '[nb-event]', this.sendEvent,
      this.element, 'click', '.nb-back', this.goBack,
      this.element, 'click', '.se-bar__mfa-onboarding__skip', this.mfaOnboardingModalClose,
      this.element, 'click', '.se-bar__mfa-onboarding__setup', this.mfaOnboardingSetup,
    ]
    if (this.hasMobileNav) bindings.push(this.$mobileTrigger, 'click', this.toggleMobileMenu)
    return bindings
  }

  goBack() {
    window.history.back()
  }

  toggleMobileMenu() {
    const open = this.mobileNavOpen = !this.mobileNavOpen
    const toggle = config.mobileNavToggle

    BODY_CLASSES[open ? 'remove' : 'add'](MOBILE_NAV_OPEN_CLASS)
    document.dispatchEvent(new CustomEvent(LEFT_NAV_TOGGLE))

    if (open) analytics.sendEvent('Main', 'Open', 'MobileMenu')
    if (toggle) return toggle()
  }

  closeMobileMenu() {
    if (!this.mobileNavOpen) return
    this.mobileNavOpen = false

    const close = config.mobileNavClose
    const toggle = config.mobileNavToggle

    BODY_CLASSES.remove(MOBILE_NAV_OPEN_CLASS)
    document.dispatchEvent(new CustomEvent(LEFT_NAV_CLOSE))

    if (close) close()
    else if (toggle) toggle()
  }

  sendEvent(e) {
    const el = e.data.delegatedTarget
    if (!el) return

    const data = el.getAttribute('nb-event').split(':')
    const category = data[0]
    const action = data[1]
    const label = data[2]
    const value = parseInt(data[3], 10) || undefined

    if (el.matches('a')) analytics.sendEventLink(e, category, action, label, value)
    else analytics.sendEvent(category, action, label, value)
  }

  // we want to do this outside the normal load flow since we do not need to rerender
  afterUpdate() {
    this.revealNginBar()
  }

  revealNginBar() {
    setTimeout(() => {
      this.element.classList.add('nb-shown')
      // reflow for IE after stylesheet is loaded and ngin bar is shown
      document.body.className = document.body.className
      this.readyHandlers.forEach(handler => {
        try { handler(this) }
        catch(err) {
          console.error('Error running readyHandler', { handler, error: err })
        }
      })
      this.readyHandlers.length = 0
      this.ready = true
    }, 0)
  }

  mfaOnboardingModalClose() {
    document.getElementById('mfa-onboarding-modal').style.display = 'none'
  }

  mfaOnboardingSetup() {
    window.location.href = `${_URLS.userService}/v3/mfa_onboarding/new?user_return_to=${encodeURIComponent(window.location.href)}`
  }
}
