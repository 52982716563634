import Component from '../lib/component'
import Icon from './icon'
import MessagesIndicatorDot from './messages-indicator-dot'
import messagesService from '../services/messages'
import templateFn from './messages-menu-item.ejs'

class MessagesMenuItem extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.messageCount = 0
  }

  init() {
    this.icon = new Icon({ parent: this, icon: 'envelope', class: 'nb-list-item__icon' })
    this.indicator = new MessagesIndicatorDot({ parent: this })
    this.submenuIcon = new Icon({ parent: this, icon: 'chevronRight', class: 'nb-list-item__submenu-icon' })
    messagesService.on('unreadCount', this.update)
  }

  update(unreadCount) {
    if (this.messageCount === unreadCount) return
    this.messageCount = unreadCount
    if (this.element) this._update()
  }

}

export default MessagesMenuItem
