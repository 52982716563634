import Component from '../lib/component'

import templateFn from './indicator-dot.ejs'

const INDICATOR = `nb-indicator-dot`
const INDICATOR_OFF = `${INDICATOR}--off`
const INDICATOR_ON = `${INDICATOR}--on`

class IndicatorDot extends Component {
  /**
   * @param options {{ status: {number | boolean | string} }}
   */
  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.indicatorStates = {
      off: INDICATOR_OFF,
      on: INDICATOR_ON,
    }
    this.setStatus(this.status)
  }

  setStatus(val) {
    switch (typeof val) {
      case 'string':
        this.status = true
        this.display = val
        break

      case 'number':
        this.status = val > 0
        this.display = val > 0 ? val : ''
        break

      default:
        this.status = !!val
        this.display = ''
    }
  }

  updateStatus(val) {
    this.setStatus(val)
    const el = this.element
    if (el) {
      el.innerText = this.display
      el.classList.remove(this.status ? INDICATOR_OFF : INDICATOR_ON)
      el.classList.add(this.status ? INDICATOR_ON : INDICATOR_OFF)
    }
  }
}

export default IndicatorDot
