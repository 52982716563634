import { SubscriptionTracker } from '../services'

import { AuthenticatedGlobalSessionState } from './global-session-state'
import { SessionStateService } from './session-state.service'
import { SignOutReason } from './sign-out-reason'

export class AuthenticationService extends SubscriptionTracker {

  /**
   * Returns `true` if the specified {@paramref state} has an expiration date before or equal to the current time,
   * otherwise `false`.
   *
   * @param state
   */
  public static isExpired(state: AuthenticatedGlobalSessionState): boolean {
    return state.expires_at <= Date.now()
  }

  /**
   * Returns `true` if the provided {@paramref obj} contains all the required properties of
   * {@link AuthenticatedGlobalSessionState}; otherwise, `false`.
   *
   * This method does *not* verify the state of the data, such as whether the session is expired, only that the
   * properties exist on {@paramref obj}.
   *
   * {@see isAuthenticated}
   *
   * @param obj
   */
  public static isValid(obj: any): obj is AuthenticatedGlobalSessionState {
    return !!(obj && obj.id && obj.expires_at && obj.alert_after && obj.ping_after)
  }

  /**
   * Returns `true` if the provided {@paramref obj} is a valid and unexpired {@link AuthenticatedGlobalSessionState}
   * object; otherwise, `false`.
   *
   * {@see isValid}
   * {@see isExpired}
   *
   * @param obj
   */
  public static isAuthenticated(obj: any): obj is AuthenticatedGlobalSessionState {
    return AuthenticationService.isValid(obj) && !AuthenticationService.isExpired(obj)
  }

  /**
   * Returns `true` if the current {@link GlobalSessionState} object is valid an not expired; otherwise, `false`.
   *
   * {@see isValid}
   * {@see isAuthenticated}
   */
  public get authenticated(): boolean {
    const state = this.sessionState$.currentSessionState
    return AuthenticationService.isAuthenticated(state)
  }

  constructor(
    private readonly sessionState$: SessionStateService,
  ) {
    super()
  }

  public signOut(reason: SignOutReason): void {
    this.sessionState$.next({
      authenticated: false,
      reason,
      trace_id: this.sessionState$.currentSessionState?.trace_id,
      prev_trace_id: this.sessionState$.currentSessionState?.prev_trace_id,
    })
  }

}
