const translations = _I18N // use webpack define based translation until multi-language considerations are necessary
const defaultLanguage = 'en'
const supportedLanguages = ['en']
let language

function t(pathKey, tokens) {
  if (typeof pathKey !== 'string') return console.error(`Translation key is an invalid type (${typeof pathKey}):`, pathKey)
  if (!pathKey) return console.lerror('Translation key cannot be an empty string.')
  tokens = tokens || {}
  let key, data = translations, plural = 'count' in tokens
  const path = pathKey.split('.')
  while (data && path.length) {
    key = path.shift()
    // use the plural form for the final path part if necessary
    if (!path.length && plural) key = pluralKey(key, tokens.count, data)
    data = data[key]
  }
  // return key as a string if it is missing
  if (data) return applyTokens(data, tokens)
  if (plural) console.error(`No translation found for key '${pathKey}' (${key}).`)
  else console.error(`No translation found for key '${pathKey}'.`)
  return pathKey
}

// For now we assume 1 is singular and all others are plural. As we support more complex langualges, this will need to be changed.
function pluralKey(key, count, data) {
  if (count === 1) return key
  if (count === 0 && `${key}_none` in data) return `${key}_none`
  return `${key}_plural`
}

function applyTokens(str, tokens) {
  let index, key, val, rx
  for (key in tokens) {
    if (!tokens.hasOwnProperty(key)) continue
    val = tokens[key]
    if (typeof val === 'number') val = val.toString()
    if (typeof val !== 'string') continue
    rx = new RegExp('\\$\\{'+key+'\\}', 'g')
    str = str.replace(rx, tokens[key])
  }
  return str
}

// `detectLanguage()` adapted from https://github.com/i18next/i18next-browser-languageDetector/blob/master/i18nextBrowserLanguageDetector.js#L128
function detectLanguage() {
  const detected = []
  const nav = navigator || {}

  if (nav.languages) {
    // chrome only; not an array, so can't use .push.apply instead of iterating
    for (var i = 0; i < nav.languages.length; i++) {
      detected.push(nav.languages[i])
    }
  }
  if (nav.userLanguage) detected.push(nav.userLanguage)
  if (nav.language) detected.push(nav.language)

  while (detected.length) {
    const lang = detected.shift()
    if (supportedLanguages.indexOf(lang) !== -1) return lang
  }

  return defaultLanguage
}

function loadLanguage() {
  const lang = detectLanguage()
  // TODO: once we go serverless, ajax load translation data based on the language
  return new Promise(resolve => resolve(language = translations[lang]))
}

export { t, loadLanguage }
export default t
