import config from '../lib/config'
import axios from 'axios'

export function loadStyles() {
  return new Promise((resolve, reject) => {
    const stylesheet = document.createElement('link')
    stylesheet.id = 'nginBarCss'
    stylesheet.rel = 'stylesheet'
    stylesheet.href = `${config.urls.snapTheme}/main-v2.css?20190108`
    document.head.appendChild(stylesheet)
    stylesheet.addEventListener('load', () => resolve())
    stylesheet.addEventListener('error', (err) => reject(err))
  })
}

export function loadSpriteSheet() {
  return axios.get(config.urls.svg)
    .then((response) => {
      const spritesheet = document.createElement('span')
      spritesheet.innerHTML = response.data
      document.body.appendChild(spritesheet)
    })
}

export function loadAllResources() {
  return Promise.all([
    loadSpriteSheet(),
    loadStyles(),
  ])
}
