import Dropdown from './dropdown'
import Avatar from './avatar'
import switcherService from '../services/switcher'

import templateFn from './switcher-dropdown.ejs'

class SwitcherDropdown extends Dropdown {
  constructor(options) {
    super(options)
    this.fetchedTeams = false
    this.templateFn = templateFn
  }

  open() {
    super.open()
    return switcherService
      .getTeamMenu()
      .then(() => {
        this.updateMenus(switcherService.menus)
      })
      .finally(() => {
        this.fetchedTeams = true
        this._update()
      })
  }

  updateMenus(menus) {
    this.menus = menus
    menus.forEach(this.createAvatars)
  }

  createAvatars(menu) {
    menu.items.forEach(this.createAvatar)
  }

  createAvatar(item) {
    if (item.avatar) item.avatar = new Avatar(Object.assign({ parent: this }, item.avatar))
  }
}

export default SwitcherDropdown
