import Component from '../lib/component'
import Icon from './icon'
import { getSportIcon } from '../lib/sport-icon'
import { contrastOk } from '../lib/color-helpers'

// TODO: Update this to handle org and team avatars (sport ball and shield)
import templateFn from './avatar.ejs'

const TEAM_TYPE_SYNONYMS = ['team', 'team_instance']

class Avatar extends Component {

  // CONSTRUCTOR OPTIONS
  //
  // identity: string
  // type: string (team|organization|person)
  // sport: string (only used with team type)
  // color: string (only used with team type)
  // imgUrl: string
  // size: number (100|200|300|500|700|800|900)

  constructor(options) {
    super(options)
    this.templateFn = templateFn
  }

  load() {
    const useFallback = () => {
      const iconName = this.getIcon()
      if (!this.isTeamType()) this.abbrev = this.getAbbrev() // teams just show a sport ball
      if (iconName) {
        this.icon = new Icon({
          parent: this,
          icon: iconName,
          class: 'nb-avatar__icon',
          color: this.getColor()
        })
      } else {
        this.background = '<span class="nb-avatar__background"></span>'
      }
    }

    if (!this.imgUrl) return useFallback()

    const loader = document.createElement('img')
    const body = document.body
    const alt = this.identity || ''

    loader.setAttribute('src', this.imgUrl)
    loader.setAttribute('alt', alt)
    loader.setAttribute('style', 'position:fixed;height:0;width:0;')

    return new Promise((resolve, reject) => {

      loader.addEventListener('error', () => {
        useFallback()
        body.removeChild(loader)
        resolve()
      })

      loader.addEventListener('load', () => {
        this.image = `<img src="${this.imgUrl}" alt="${alt}" class="nb-avatar__image">`
        body.removeChild(loader)
        resolve()
      })

      body.appendChild(loader)
    })
  }

  render() {
    const markup = super.render()
    let className = `nb-avatar nb-avatar--${this.type}`
    if (this.size) className += ` nb-avatar--${this.size}`
    return markup.replace(/(<[a-z-]+)/, `$1 class="${className}"`)
  }

  getIcon() {
    if (this.type === 'organization') return 'shield'
    if (this.isTeamType()) return getSportIcon(this.sport)
  }

  getAbbrev() {
    const str = this.identity || ''
    const initials = str.split(/\s+/).map(str => str[0])
    initials.length = this.type === 'person' ? 2 : 1
    return `<span class="nb-avatar__abbrev">${initials.join('')}</span>`
  }

  getColor() {
    if (this.color && this.isTeamType() && contrastOk(this.color, '#FFFFFF')) return this.color
  }

  isTeamType() {
    return TEAM_TYPE_SYNONYMS.includes(this.type)
  }

}

export default Avatar
