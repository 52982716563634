import Component from '../lib/component'
import messagesService from '../services/messages'
import templateFn from './messages-footer.ejs'

class MessagesFooter extends Component {
  constructor(options) {
    super(options)
    this.templateFn = templateFn
    messagesService.on('messages', this.update)
  }

  update(messages) {
    this.showFooter = !!messages.length
    if (this.element) this._update()
  }

}

export default MessagesFooter
