import { combineLatest, interval, merge, Observable, of, Subject, throwError } from 'rxjs'
import { finalize, map, takeWhile } from 'rxjs/operators'

import Component from '../../lib/component'
import { AuthenticatedGlobalSessionState } from '../../session-state'

import { Countdown } from './countdown'

const { templateFn } = require('./session-inactivity-warning.ejs')

const COUNTDOWN_INTERVAL_MS = 1000

const COUNTDOWN_ELEMENT_ID = 'nb-session-countdown'

export class SessionInactivityWarning extends Component {

  public readonly extend$: Observable<void>
  public readonly signOut$: Observable<void>
  public readonly countdownContent = `<span id="${COUNTDOWN_ELEMENT_ID}"></span>`

  private readonly extend$$ = new Subject<void>()
  private readonly signOut$$ = new Subject<void>()
  private readonly countdown: Countdown

  protected get eventBindings() {
    if (!this.element) {
      return []
    }
    return [
      this.element, 'click', '.se-bar__session-inactivity-warning__extend', this.onExtend,
      this.element, 'click', '.se-bar__session-inactivity-warning__sign_out', this.onSignOut,
    ]
  }

  constructor(state: AuthenticatedGlobalSessionState, parent: Component) {
    super({ parent })
    this.templateFn = templateFn

    this.extend$ = this.extend$$.asObservable()
    this.signOut$ = this.signOut$$.asObservable()

    this.countdown = new Countdown(this.initCountdown(state), this, COUNTDOWN_ELEMENT_ID)
  }

  private initCountdown(state: AuthenticatedGlobalSessionState): Observable<number> {
    const interval$ = merge(of(0), interval(COUNTDOWN_INTERVAL_MS))
    return combineLatest([of(state), interval$]).pipe(
      takeWhile(([state]) => state.expires_at > Date.now()),
      map(([state]) => this.toCountdownData(state)),
      finalize(() => throwError(new Error('Session Expired!')))
    )
  }

  private toCountdownData(state: AuthenticatedGlobalSessionState): number {
    if (!state) {
      return undefined
    }

    return Math.ceil((state.expires_at - Date.now()) / 1000)
  }

  public dispose(): void {
    super.dispose()
    this.extend$$.complete()
    this.signOut$$.complete()
  }

  private onExtend(): void {
    this.extend$$.next()
  }

  private onSignOut(): void {
    this.signOut$$.next()
  }

}
