import { BehaviorSubject, combineLatest, Observable, of, race, throwError } from 'rxjs'
import { catchError, map, mergeMap, share, shareReplay, tap } from 'rxjs/operators'

import { MutexClient, MutexLock, MutexLockAbortedError, MutexRequestCanceledError } from '../cross-domain'
import { SubscriptionTracker } from '../services'
import {
  AuthenticatedGlobalSessionState,
  GlobalSessionState,
  UnauthenticatedGlobalSessionState,
} from './global-session-state'

import { GlobalSessionEndpoint } from './global-session.endpoint'
import { UnauthorizedGlobalSessionResponseError } from './invalid-global-session-response-error'
import { PingRequest, PingRequestProtection } from './ping-request'
import { SignOutReason } from './sign-out-reason'
import { UserActivity } from './user-activity-collector'
import { UserActivityService } from './user-activity.service'

export const PING_RESOURCE_KEY = 'se-bar:session-state:ping'
const PING_RESOURCE_TIMEOUT_MS = 5000
const PING_RESOURCE_COOLDOWN_MS = 250

export interface PingDebugState {
  requestedLock?: boolean
  grantedLock?: boolean
  sentPing?: boolean
  receivedPing?: boolean
}

export class PingService extends SubscriptionTracker {

  public readonly debug$: Observable<PingDebugState>

  private lastDebugState: PingDebugState = {}
  private readonly debug$$ = new BehaviorSubject<PingDebugState>(undefined)

  private pingRequest: PingRequest

  public get pingInProgress(): boolean {
    return !!this.pingRequest
  }

  constructor(
    private readonly endpoint: GlobalSessionEndpoint,
    private readonly mutexClient: MutexClient,
    private readonly userActivity$: UserActivityService,
  ) {
    super()

    this.debug$ = this.debug$$.pipe(
      map((state) => Object.assign(this.lastDebugState, state)),
      shareReplay(),
    )
    this.resetDebug()
  }

  public ping(prevTraceId: string, sessionExpiresAt: number, protection: boolean | PingRequestProtection = false): Observable<GlobalSessionState> {
    if (this.pingInProgress) {
      console.log('[se-bar PingService] skipping ping - existing ping request')
      if (protection) {
        this.pingRequest.protect(prevTraceId, protection)
      }
      return this.pingRequest.response$
    }

    console.log('[se-bar PingService] requesting lock for ping', { protection })
    const lockRequest$ = this.mutexClient.request(PING_RESOURCE_KEY, PING_RESOURCE_TIMEOUT_MS)

    const response$ = lockRequest$.pipe(
      mergeMap(lock => {
        this.debug$$.next({ grantedLock: true })

        console.log('[se-bar PingService] received lock for ping')
        const lastActivity = this.userActivity$.lastUserActivity
        const ping$ = this.execPing(lock, lastActivity, sessionExpiresAt)

        this.debug$$.next({ sentPing: true })

        const abort$ = this.pingRequest.abort$.pipe(
          catchError(err => {
            lock.release()
            return throwError(err)
          }),
        )

        return combineLatest([of(lock), race(ping$, abort$)])
      }),
      map(([lock, state]) => {
        const effectivePrevTraceId = this.pingRequest.prevTraceId
        this.debug$$.next({ receivedPing: true })

        // release after a cooldown to ensure that the state next gets to other clients before they can be granted
        // a ping
        setTimeout(() => {
          lock.release()

          this.resetDebug()
        }, PING_RESOURCE_COOLDOWN_MS)
        return Object.assign(state, {
          prev_trace_id: effectivePrevTraceId,
        })
      }),
      tap(console.log.bind(console, '[se-bar PingService] ping emit')),
      share(),
    )

    const done = this.onPingComplete.bind(this)
    const subscription = response$.subscribe({ complete: done, error: done })
    this.pingRequest = new PingRequest(prevTraceId, protection, lockRequest$, response$, subscription)

    this.debug$$.next({ requestedLock: true })

    return this.pingRequest.response$
  }

  public abort(reason?: string, forTraceId?: string): void {
    if (this.pingInProgress && this.pingRequest.canAbort(forTraceId)) {
      console.log('[se-bar PingService] abort', { reason, forTraceId })
      if (this.pingRequest.lockRequest$.granted) {
        this.pingRequest.abort()
      } else {
        this.pingRequest.lockRequest$.cancel(reason || 'abort')
      }
    }
  }

  private execPing(lock: MutexLock, lastActivity: UserActivity, sessionExpiresAt: number): Observable<GlobalSessionState> {
    let request: Observable<AuthenticatedGlobalSessionState>
    if (lastActivity) {
      const lastActivityFromExpires = sessionExpiresAt ? sessionExpiresAt - lastActivity.ts : 1
      request = this.endpoint.ping(lastActivityFromExpires)
    } else {
      request = this.endpoint.fetch()
    }
    return request.pipe(
      catchError(err => {
        lock.release()

        this.resetDebug()

        if (err instanceof UnauthorizedGlobalSessionResponseError) {
          return of({
            authenticated: false,
            reason: SignOutReason.unknown,
            trace_id: err.traceId,
            prev_trace_id: this.pingRequest.prevTraceId,
          } as UnauthenticatedGlobalSessionState)
        }

        return throwError(err)
      })
    )
  }

  private onPingComplete(errOrCanceled?: Error): void {
    this.pingRequest.dispose()
    this.pingRequest = undefined
    let result: string
    if (errOrCanceled instanceof Error) {
      if (MutexRequestCanceledError.isMutexCanceledError(errOrCanceled)) {
        result = 'canceled'
      } else if (MutexLockAbortedError.isMutexAbortedError(errOrCanceled)) {
        result = 'aborted'
      } else {
        result = 'error'
      }
    } else {
      result = 'complete'
    }
    console.log('[se-bar PingService] ping %s', result)

    this.resetDebug()
  }

  private resetDebug(): void {
    this.debug$$.next({
      requestedLock: false,
      grantedLock: false,
      sentPing: false,
      receivedPing: false,
    })
  }

}
