import { hasTouch } from '../lib/ui-helpers'
import { nonPassiveCapture } from './event-utility'

const scrollableSelectors = []
const scrollableConditions = [] // scrollable selector and condition pair
let lastTouch = { x: 0, y: 0 }

// Remember scrollables (and conditions) that are allowed to scroll. Preventing overscroll on those
// elements and preventing any scrolling on other elements, when the conditions are met.
function preventOtherTouchScrolling(selector, conditions) {
  if (!hasTouch) return
  if (selector.forEach) return selector.forEach(selector => preventOtherTouchScrolling(selector, conditions))
  if (scrollableSelectors.includes(selector)) return
  if (!scrollableSelectors.length) restrictScrolling(true)
  scrollableSelectors.push(selector)
  if (conditions) scrollableConditions.push({ selector, conditions })
}

// Forget scrollables (and conditions) that were paseed to `preventOtherTouchScrolling()`,
// restoring scrolling to other elements if no other elements are still remembered.
function allowOtherTouchScrolling(selector) {
  if (selector.forEach) return selector.forEach(selector => allowOtherTouchScrolling(selector))
  const selectorIndex = scrollableSelectors.indexOf(selector)
  if (selectorIndex === -1) return
  scrollableSelectors.splice(selectorIndex, 1)
  const conditionIndex = scrollableConditions.findIndex(c => c.selector === selector)
  if (conditionIndex !== -1) scrollableConditions.splice(conditionIndex, 1)
  if (!scrollableSelectors.length) restrictScrolling(false)
}

// Restricts scrolling to only those elements that have been remembered with `preventOtherTouchScrolling()`.
function restrictScrolling(bool) {
  const addOrRemove = `${bool ? 'add' : 'remove'}EventListener`
  document[addOrRemove]('touchmove', touchmove, nonPassiveCapture)
  document[addOrRemove]('touchstart', touchstart, nonPassiveCapture)
  document[addOrRemove]('touchend', touchend, nonPassiveCapture)
}

// Internal - Used to prevent touchmove events (and scrolling) when elements are remembered with `preventOtherTouchScrolling()`.
function touchmove(e) {
  if (!scrollableSelectors.length) return
  let el
  while ((el = el ? el.parentNode : e.target) && el !== document) {
    const scrollableSelector = matchedSelector(el)
    if (scrollableSelector && (!scrolledToEdge(e, el) || !scrollableConditionsMet(scrollableSelector))) return
  }
  e.preventDefault()
}

// Internal - Used to track touch position to determine if we are swiping up or down.
function touchstart(e) {
  lastTouch = getTouch(e)
}

// Internal - Used to reset touch tracking when a gesture is complete.
function touchend() {
  lastTouch = { x: 0, y: 0 }
}

function getTouch(e) {
  return {
    x: e.changedTouches[0].screenX,
    y: e.changedTouches[0].screenY
  }
}

function matchedSelector(el) {
  for (let i = 0; i < scrollableSelectors.length; i++) {
    do {
      if (el.matches(scrollableSelectors[i])) return scrollableSelectors[i]
    } while (el = el.parentElement)
  }
}

function scrolledToEdge(e, el) {
  const prevTouch = lastTouch
  const thisTouch = getTouch(e)
  lastTouch = thisTouch
  // swipe up at top
  if (el.scrollTop === 0 && prevTouch.y < thisTouch.y) return true
  // swipe down at bottom
  if ((el.scrollHeight - el.scrollTop - el.clientHeight) < 1 && prevTouch.y > thisTouch.y) return true
}

function scrollableConditionsMet(el) {
  const c = scrollableConditions.find(c => c.el = el)
  return c ? c.conditions['when']() : false
}

// Resets an elements scroll position to the top
function resetScroll(selector) {
  const els = document.querySelectorAll(selector) || []
  els.forEach(el => el.scrollTop = 0)
}

export {
  preventOtherTouchScrolling,
  allowOtherTouchScrolling,
  resetScroll
}
