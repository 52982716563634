import Component from '../lib/component'
import analytics from '../lib/analytics'
import { eventHandledBy, handleEvent } from '../lib/event-utility'

import Avatar from './avatar'
import Icon from './icon'
import Tooltip from './tooltip'

import templateFn from './menu-button.ejs'

class MenuButton extends Component {

  /***** CONSTRUCTOR OPTIONS *****/

  // label: string (required)
  // icon: string (required if no avatarData)
  // avatarData: (required if no icon)

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.active = false
    this.dropdownClass = options.dropdownClass
    this.dropdownOptions = options.dropdownOptions || {}
  }

  get eventBindings() {
    return [
      this.element, 'mousedown', '[nb-close-dropdown-desktop]', this.setTarget,
      this.element, 'click', '[nb-close-dropdown-desktop]', this.closeClick,
      this.element, 'click', '.nb-menu-dropdown', this.dropdownClick,
      this.element, 'click', this.buttonClick,
      this.element, 'keydown', this.keyClick,
      this.doc, 'click', this.docClick,
      this.doc, 'focusin', this.closeDropdownOnOtherFocus
    ]
  }

  init() {
    this.indicatorDot = this.indicatorClass ? new this.indicatorClass({ parent: this }) : ''
    this.tooltip = new Tooltip({ parent: this, text: this.label })
    if (this.icon) {
      this.iconSvg = new Icon({ parent: this, icon: this.icon, class: 'nb-menu-button__icon' })
    } else if (this.avatarData) {
      this.avatar = new Avatar(Object.assign({ parent: this, class: 'nb-menu-button__icon' }, this.avatarData))
    }
    this.dropdown = this.dropdownClass && new this.dropdownClass(Object.assign({ parent: this }, this.dropdownOptions))
  }

  setTarget(e) {
    const el = e.data.delegatedTarget
    if (!el || !el.getAttribute('href') || el.getAttribute('target')) return
    el.setAttribute('target', '_self')
  }

  buttonClick(e) {
    handleEvent(e, `${this._elementId}-button`)
  }

  keyClick(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      // only catch the space and enter if the span is selected
      if (e.srcElement.parentElement == this.element) {
        e.preventDefault()
        this.element.click()
      }
    }
  }

  dropdownClick(e) {
    handleEvent(e, `${this._elementId}-dropdown`)
  }

  closeClick(e) {
    // This is used to close the dropdowns on desktop only. There is a behavior on ios where the menu starts to slide
    // closed, but the animation is paused as navigation to the new page starts, and it looks really strange. To avoid
    // that, we have decided to force all dropdown links that navigate to have at lease a "_self" target to break out of
    // any Angular navigation and trigger a full page refresh. We will also keep the dropdown open until the navigation
    // completes and the new page is displayed.
    if (!this.dropdown.isFullScreen()) handleEvent(e, `${this._elementId}-close`)
  }

  closeDropdownOnOtherFocus(event) {
    if (!this.dropdown?.element?.contains(event.target) && !this.dropdown.isFullScreen()) {
      this.dropdown.close()
    }
  }

  docClick(e) {
    if (eventHandledBy(e, `${this._elementId}-close`)) return this.deactivate()
    if (eventHandledBy(e, `${this._elementId}-dropdown`)) return // do nothing
    if (eventHandledBy(e, `${this._elementId}-button`) && !this.active) return this.activate()
    this.deactivate()
  }

  activate() {
    if (this.active) return
    this.element.firstElementChild.setAttribute('aria-expanded', (this.active = true))
    analytics.sendEvent('Main', 'Open', this.gaLabel)
    if (this.dropdown) this.dropdown.open(true)
    this.tooltip.disabled = true
  }

  deactivate() {
    if (!this.active) return
    this.element.firstElementChild.setAttribute('aria-expanded', (this.active = false))
    if (this.dropdown) this.dropdown.close(true)
    this.tooltip.disabled = false
  }

  statusDisplay() {
    const status = this.status
    return (typeof status === 'number' && status > 0) ? status : ''
  }

}

export default MenuButton
