import { SubscriptionTracker } from '../services'

import { CrossDomainClient } from './cross-domain-client'
import { MutexClientRequest, MutexRequestOptions } from './mutex-client-request'

export class MutexClient extends SubscriptionTracker {

  constructor(private xdClient: CrossDomainClient) {
    super()
  }

  public request(
    resourceKey: string,
    releaseTimeout: number,
    options: MutexRequestOptions = {},
  ): MutexClientRequest {
    return new MutexClientRequest(this.xdClient, resourceKey, releaseTimeout, options || {})
  }

}
