/**
 * A flag representing the state of a user.
 */
export enum UserState {
  /**
   * The user state cannot be determined, likely because the session state data has not been loaded yet.
   */
  unknown = 'unknown',

  /**
   * The user is actively using an application.
   */
  active = 'active',

  /**
   * The user has not been active in any applications recently (inactivity alert).
   */
  idle = 'idle',

  /**
   * The user has logged out, or their session has expired due to inactivity.
   */
  expired = 'expired',
}
