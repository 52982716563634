function getImageUrlByType(images, type) {
  const image = images.find((image) => image.image_type === type)
  if (image) {
    return image.url
  }
}

function determineAvatar(images) {
  return customAvatar(
    getImageUrlByType(images, 'crop') ||
    getImageUrlByType(images, 'crop_icon') ||
    getImageUrlByType(images, 'original')
  )
}

// ignore the default profile avatar
function customAvatar(url='') {
  var isDefault = !!(url).match(/images\/avatar.svg$/)
  return isDefault ? '' : url
}

export {
  customAvatar,
  determineAvatar
}
