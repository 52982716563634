import Component from '../lib/component'
import Avatar from './avatar'
import chatService from '../services/chat'
import templateFn from './chat-list.ejs'

class ChatList extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    chatService.on('teams', this.update)
  }

  update(teams) {
    this.teams = teams
    this.teams.forEach(team =>  team.avatar = new Avatar(Object.assign({ parent: this }, team.avatar)))
    if (this.element) this._update()
  }

}

export default ChatList
