import Component from '../lib/component'
import notificationsService from '../services/notifications'
import templateFn from './notifications-list.ejs'
import inviteBadges from '../lib/invite-badges'
import statusIcons from '../lib/status-icons'
import t from '../lib/i18n'
import { multiToggleFactory } from '../lib/ui-helpers'
import config from '../lib/config'

import {
  ACTION_STATUS_APPLICATION_ERROR,
  ACTION_STATUS_CONNECTION_ERROR,
  ACTION_STATUS_OK,
  ACTION_STATUS_PROCESSING,
  ACTION_STATUS_RESPONSE_ERROR,
  NOTIFICATION_METHOD_ACCEPT,
  NOTIFICATION_METHOD_DECLINE,
  NOTIFICATION_MODE_DONE,
  NOTIFICATION_MODE_PROCESSING,
  NOTIFICATION_TYPE_GUARDIAN_INVITE,
  NOTIFICATION_TYPE_ORPHAN_INVITE,
  NOTIFICATION_TYPE_ROSTER_INVITE,
  NOTIFICATION_TYPE_TEAM_INVITE,
  NOTIFICATION_VIEW_ACTION,
  NOTIFICATION_VIEW_INFO,
} from '../lib/constants'

const dismissalDelayMs = 2500
const delay = (delayMs) =>
  new Promise((resolve) => setInterval(resolve, delayMs))

const extractNotificationData = (node, prop) => node.getAttribute(`data-notification-${prop}`)

const toggleView = multiToggleFactory(
  [
    NOTIFICATION_VIEW_ACTION,
    NOTIFICATION_VIEW_INFO
  ],
  (view) => `nb-notifications-list__item--${view}`
)

const toggleStatus = multiToggleFactory(
  [
    ACTION_STATUS_APPLICATION_ERROR,
    ACTION_STATUS_CONNECTION_ERROR,
    ACTION_STATUS_OK,
    ACTION_STATUS_PROCESSING,
    ACTION_STATUS_RESPONSE_ERROR,
  ],
  (status) => `nb-notification-action-progress--${status}`
)


const doneText = (notificationMethod) => t(`NOTIFICATIONS.done_${notificationMethod}`)
const processingText = (notificationMethod) => t(`NOTIFICATIONS.processing_${notificationMethod}`)
const subjectText = (notificationType) => t(`NOTIFICATIONS.subject_${notificationType}`)

const actionMessage = (notificationMethod, notificationType, actionStatus, error) => {
  if (actionStatus === ACTION_STATUS_APPLICATION_ERROR) return `${statusIcons[ACTION_STATUS_RESPONSE_ERROR]} ${t('NOTIFICATIONS.application_error')}`
  if (actionStatus === ACTION_STATUS_CONNECTION_ERROR) return `${statusIcons[ACTION_STATUS_CONNECTION_ERROR]} ${t('NOTIFICATIONS.error_connection_lost')}`
  if (actionStatus === ACTION_STATUS_OK) return `${statusIcons[ACTION_STATUS_OK]} ${doneText(notificationMethod)}`
  if (actionStatus === ACTION_STATUS_PROCESSING) return `${processingText(notificationMethod)} ${subjectText(notificationType)}...`
  if (actionStatus === ACTION_STATUS_RESPONSE_ERROR) return `${statusIcons[ACTION_STATUS_RESPONSE_ERROR]} ${t('NOTIFICATIONS.error_occured')}: \n${error.data.messages.join('\n')}.`
}

class NotificationsList extends Component {
  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.inviteBadges = inviteBadges
    this.statusIcons = statusIcons
    this.config = config
  }

  get eventBindings() {
    return [
      this.element, 'click', this.clickHandler
    ]
  }

  init() {
    notificationsService.getNotificationsPollOn(this.update)
  }

  update(notifications) {
    this.notifications = notifications
    this.notificationsLoaded = true
    if (this.element) this._update()
  }

  clickHandler(event) {
    const target = event.target
    if (!target.matches('[data-notification-method]')) return
    const item = target.closest('.nb-notifications-list__item')
    const notificationType = extractNotificationData(item, 'type')
    const notificationId = extractNotificationData(item, 'id')
    const notificationUuid = extractNotificationData(item, 'uuid')
    const notificationMethod = extractNotificationData(target, 'method')
    const notificationInfo = {
      type: notificationType,
      id: notificationId,
      method: notificationMethod,
      uuid: notificationUuid
    }
    if ((notificationType === NOTIFICATION_TYPE_ORPHAN_INVITE ||
        notificationType === NOTIFICATION_TYPE_ROSTER_INVITE) && notificationMethod === NOTIFICATION_METHOD_ACCEPT) return

    // set the retry method
    item
      .querySelector('.nb-notification-action-progress__retry-button')
      .setAttribute('data-notification-method', notificationInfo.method)

    this.changeActionStatus(notificationInfo, ACTION_STATUS_PROCESSING)
    this.changeItemView(notificationInfo, NOTIFICATION_VIEW_ACTION)

    // clear the sessionStorage cache so fresh info is obtained upon page refresh
    notificationsService.getNotificationsClearCache()

    notificationsService
      .submitNotificationAction(notificationInfo)
      .then(() => {
        this.changeActionStatus(notificationInfo, ACTION_STATUS_OK)
        item.classList.add('nb-notifications-list__item--dismissed')
        return delay(dismissalDelayMs)
      })
      .then(() => {
        window.postMessage({ notificationType, notificationId, type: 'notification:resolved' })
      })
      .catch((error) => {
        // We received a response but there was an error
        if (error.response) {
          if (error.response.status == 404)
            return this.changeActionStatus(notificationInfo, ACTION_STATUS_OK)
          else
            return this.changeActionStatus(notificationInfo, ACTION_STATUS_RESPONSE_ERROR, error)
        }
        // We never received a response, most likely due to timeout or network error
        if (error.request) return this.changeActionStatus(notificationInfo, ACTION_STATUS_CONNECTION_ERROR, error)
        // There was an error initializing or negotiating the connection
        return this.changeActionStatus(notificationInfo, ACTION_STATUS_APPLICATION_ERROR, error)
      })
  }

  changeItemView({ id }, view) {
    const item = this.element.querySelector(`[data-notification-id='${id}']`)
    toggleView(item, view)
  }

  changeActionStatus({ id, method, type }, status, error) {
    const progress = this.element.querySelector(`[data-notification-id='${id}'] .nb-notification-action-progress`)
    const progressMessage = this.element.querySelector(`[data-notification-id='${id}'] .nb-notification-action-progress__message`)
    progressMessage.innerHTML = actionMessage(method, type, status, error)
    toggleStatus(progress, status)
  }

  buildPhrase(notification) {
    const emph = (text) => `<span class="nb-notification-summary__emphasis">${text}</span>`
    switch (notification.notification_type) {
      case NOTIFICATION_TYPE_GUARDIAN_INVITE:
        return `${emph(notification.owner_persona_name)} added you as a guardian for ${emph(notification.persona.name)}`
      case NOTIFICATION_TYPE_ORPHAN_INVITE:
        return `You have been invited to claim ${emph( notification.persona.name)}`
      case NOTIFICATION_TYPE_ROSTER_INVITE:
        return `${emph(notification.organization_name)} added ${emph(notification.persona.name)} to their roster: ${emph(notification.roster_team_name)}`
      case NOTIFICATION_TYPE_TEAM_INVITE:
        return `${emph(notification.organization_name)} invited ${emph(notification.persona.name)} to join their team: ${emph(notification.team_name)}`
      default:
        return ''
    }
  }

  buildAcceptUrl(notification) {
    switch (notification.notification_type) {
      case NOTIFICATION_TYPE_ORPHAN_INVITE:
      case NOTIFICATION_TYPE_ROSTER_INVITE:
        return `${this.config.urls.userService}/invitations/originator_redirect?originator_uuid=${notification.uuid}`
      default:
        return `${this.config.urls.userService}/invitations/${notification.token}/claim`
    }
  }
}

export default NotificationsList
