import { Observable } from 'rxjs'
import { filter } from 'rxjs/operators'

import { SubscriptionTracker } from '../services'

import { allowedMessages } from './allowed-messages'
import { CrossDomainClientMessageType } from './cross-domain-client-message-type'
import { CrossDomainHostMessageType } from './cross-domain-host-message-type'
import { CrossDomainMessage, CrossDomainMessageTypes } from './cross-domain-message'

export class CrossDomainService<
  TMessageType extends CrossDomainHostMessageType | CrossDomainClientMessageType,
  TMessage extends CrossDomainMessage<TMessageType, any>
  > extends SubscriptionTracker {

  public readonly messages$: Observable<TMessage>

  constructor(messageTypes: CrossDomainMessageTypes) {
    super()

    this.messages$ = allowedMessages(messageTypes) as Observable<TMessage>
  }

  public filterMessages<TMessageSubset extends TMessage>(...types: TMessageType[]): Observable<TMessageSubset> {
    return this.messages$.pipe(filter(message => types.includes(message.type))) as Observable<TMessageSubset>
  }

}
