import t from '../lib/i18n'
import { throttle } from '../lib/util'

const NBID = 'ngin-bar'
const hasTouch = 'ontouchstart' in window

function formatDate(isoDateString) {
  // Handle invalid format
  const date = new Date(Date.parse(isoDateString))

  if (isNaN(date.getTime())) {
    return 'Unknown'
  } else {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
    })
  }
}

function initialsFromFullName(fullName) {
  if (!fullName.trim()) {
    return '';
  }
  const words = fullName.trim().split(' ');
  const initials = words.filter(word => (word != '')).map(word => word[0].toUpperCase()).join('');
  return initials;
}

// returns a function which performs a class toggle with multiple options
const multiToggleFactory = (choiceKeys, classMapper) => (node, chosenKey) => {
  if (!choiceKeys.includes(chosenKey)) return console.error(`multiToggleFactory: '${chosenKey}' must be in the list: ${choiceKeys.join(', ')}`)
  choiceKeys.forEach((choice) => node.classList.remove(classMapper(choice)))
  node.classList.add(classMapper(chosenKey))
  return true
}

// iOS doesn't update the vh unit while browser chrome is expanding. This can lead
// to unexpected display, with full screen elements gettign cut off at the bottom.
// To fix this, we watch the size with JS and set a css --vh variable and use the
// normal vh unit as a fallback. We also track the top of the se bar for better
// menu positioning when the bar is not at the top of the window.
let cssVarsTracked = false
let lastSeBarTop
const seBarEl = document.querySelector('se-bar, #ngin-bar')
const style = document.documentElement.style
const CSS_DELAY = 30

function initializeCSSVars() {
  if (cssVarsTracked) return
  cssVarsTracked = true
  setCssOnResize()
  setCssOnScroll()
  setCssOnPoll()
  window.addEventListener('resize', throttle(setCssOnResize), CSS_DELAY)
  window.addEventListener('scroll', setCssOnScroll, true)
  setInterval(setCssOnPoll, CSS_DELAY)
}

function setCssOnResize() {
  style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
}

function setCssOnScroll() {
  setCssSeBarTop()
}

function setCssOnPoll() {
  setCssSeBarTop()
}

function setCssSeBarTop() {
  var rect = seBarEl.getBoundingClientRect()
  if (lastSeBarTop === rect.top) return
  lastSeBarTop = rect.top
  style.setProperty('--se-bar-top', `${rect.top}px`)
}

// IMPORTANT! keep this format - it's being used in esj-compiler-loader.js to determine the list of helper names to be
// destructured when compiling EJS functions. Any keys added here will automatically be added to the
// "destructuredLocals" array so they are accessible from templates.
export {
  formatDate,
  hasTouch,
  initializeCSSVars,
  initialsFromFullName,
  multiToggleFactory,
  t,
}
