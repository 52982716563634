import MenuButton from './menu-button'
import t from '../lib/i18n'
import { customAvatar } from '../lib/service-helpers'
import ProfileDropdown from './profile-dropdown'

class ProfileButton extends MenuButton {
  constructor(options) {
    const profile = options.profile
    super({
      parent: options.parent,
      label: t('PROFILE.tooltip'),
      gaLabel: 'ProfileMenu',
      dropdownClass: ProfileDropdown,
      dropdownOptions: { profile },
      avatarData: {
        type: 'person',
        identity: (profile.first_name || '') + ' ' + (profile.last_name || ''),
        imgUrl: customAvatar(profile.profile_image_url)
      }
    })
    this.profile = profile
  }

}

export default ProfileButton
