import rosterInviteBadge from '../icons/roster-invite-badge.svg'
import orphanInviteBadge from '../icons/orphan-invite-badge.svg'
import guardianInviteBadge from '../icons/guardian-invite-badge.svg'
import {
  NOTIFICATION_TYPE_GUARDIAN_INVITE,
  NOTIFICATION_TYPE_ORPHAN_INVITE,
  NOTIFICATION_TYPE_ROSTER_INVITE,
} from '../lib/constants'

export default {
  [NOTIFICATION_TYPE_ROSTER_INVITE]: rosterInviteBadge,
  [NOTIFICATION_TYPE_ORPHAN_INVITE]: orphanInviteBadge,
  [NOTIFICATION_TYPE_GUARDIAN_INVITE]: guardianInviteBadge
}
