import { fromEvent, interval, merge, Observable, of } from 'rxjs'
import { map, shareReplay, throttle } from 'rxjs/operators'

export interface UserActivity {
  type: string
  ts: number
}

export const THROTTLE_INTERVAL_MS = 500

function createLoadEvent(): Event {
  if (typeof Event === 'function') {
    // most browsers
    return new Event('load')
  }
  try {
    // IE 9/10
    return document.createEvent('load')
  } catch (err) {
    // IE 11
    const event = document.createEvent('HTMLEvents')
    event.initEvent('load', true, false)
    return event
  }
}

const EVENT_STREAMS = {
  // since this won't get subscribed to until after the actual load event fires, we sort of fake it
  load: of(createLoadEvent()),
}
/**
 * Creates an {@link Observable} that emits a {@link UserActivity} object each time any of the events in
 * {@param eventNames} is detected.
 */
export function userActivityCollector(...eventNames: string[]): Observable<UserActivity> {
  const eventStreams = eventNames.map(eventName => EVENT_STREAMS[eventName] || fromEvent(document, eventName).pipe(shareReplay()))

  return merge(...eventStreams).pipe(
    // use throttle to prevent events like mousemove from spamming
    // throttle is used over over debounce because debounce won't emit until the timeout passes without seeing an event
    // throttle emits at most every {THROTTLE_INTERVAL_MS}, and will emit immediately for events coming after
    // the interval has expired
    throttle(() => interval(THROTTLE_INTERVAL_MS)),
    map((event: Event) => ({
      ts: Date.now(),
      type: event.type,
    })),
    shareReplay(1),
  )
}
