/**
 * A mutex request is aborted when a mutex client determines it no longer needs a lock after it has been requested, but
 * the lock has already been granted
 */
export class MutexRequestAbortedError extends Error {

  public static isMutexAbortedError(err: Error): err is MutexRequestAbortedError {
    return err && ((err as any).mutexCanceled)
  }

  public readonly mutexCanceled = true

  constructor(public readonly reason?: string) {
    super(`Mutex request aborted by requester${reason ? `: ${reason}` : ''}`)
  }
}
