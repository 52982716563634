import Component from '../lib/component'
import Icon from './icon'
import NotificationsIndicatorDot from './notifications-indicator-dot'
import notificationsService from '../services/notifications'
import templateFn from './notifications-menu-item.ejs'

class NotificationsMenuItem extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.notificationCount = 0
  }

  init() {
    this.icon = new Icon({ parent: this, icon: 'bell', class: 'nb-list-item__icon' })
    this.indicator = new NotificationsIndicatorDot({ parent: this })
    this.submenuIcon = new Icon({ parent: this, icon: 'chevronRight', class: 'nb-list-item__submenu-icon' })
    notificationsService.getNotificationsPollOn(this.update)
  }

  update(notifications) {
    this.notificationCount = notifications.length
    if (this.element) this._update()
  }
}

export default NotificationsMenuItem
