/**
 * A mutex lock is aborted when a mutex client determines it no longer needs a lock after it has already been granted.
 */
export class MutexLockAbortedError extends Error {

  public static isMutexAbortedError(err: Error): err is MutexLockAbortedError {
    return err && ((err as any).mutexAborted)
  }

  public readonly mutexAborted = true

  constructor() {
    super('Mutex aborted by user')
  }

}
