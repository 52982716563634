import { SeBarApiResponse } from '../lib/request'

import { SignOutReason } from './sign-out-reason'

export interface AuthenticatedGlobalSessionResponse {
  id: number | string
  expires_in: number
  alert_in: number
  ping_in: number
  trace_id?: string
}

export interface AuthenticatedGlobalSessionState {
  id: number | string
  trace_id?: string
  authenticated: true
  expires_at: number
  alert_after: number
  ping_after: number
  prev_trace_id?: string
}

export interface UnauthenticatedGlobalSessionState {
  authenticated: false
  reason: SignOutReason
  trace_id?: string
  prev_trace_id?: string
}

export type GlobalSessionState = AuthenticatedGlobalSessionState | UnauthenticatedGlobalSessionState

export type ReceivedState<TState extends GlobalSessionState> = TState & {
  received_at: number
}

export function mapAuthenticatedResponseToState(res: SeBarApiResponse<AuthenticatedGlobalSessionResponse>): AuthenticatedGlobalSessionState {
  const now = Date.now()
  return Object.assign(res, {
    authenticated: true,
    expires_at: new Date(now + res.expires_in).valueOf(),
    ping_after: new Date(now + res.ping_in).valueOf(),
    alert_after: new Date(now + res.alert_in).valueOf(),
    trace_id: res._trace_id,
  } as AuthenticatedGlobalSessionState)
}
