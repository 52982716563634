import { buildSessionHash } from './session-utility'

const ONE_SECOND = 1000 // fudge this to stop endless polling locally (should be 1000 for deploy)
const CACHE_EXPIRY_DEFAULT_SECS = 20
const CACHE_BACKEND_MEMORY = 'memory'
const CACHE_BACKEND_SESSION_STORAGE = 'sessionStorage'
const CACHE_BACKEND_LOCAL_STORAGE = 'localStorage'
const CACHE_BACKENDS = [
  CACHE_BACKEND_MEMORY,
  CACHE_BACKEND_SESSION_STORAGE,
  CACHE_BACKEND_LOCAL_STORAGE,
]
const CACHE_DEFAULT_BACKEND = CACHE_BACKEND_SESSION_STORAGE
const CACHE_KEY_POLL_ID = 'poll-id'
const CACHE_KEY_IN_FLIGHT = 'in-flight'
const CACHE_KEY_TIMER = 't'
const CACHE_KEY_EXPIRY = 'x'
const CACHE_KEY_SESSION_HASH = 'i'

const CACHE_CTRL_KEYS = [
  CACHE_KEY_IN_FLIGHT,
  CACHE_KEY_TIMER,
  CACHE_KEY_TIMER,
  CACHE_KEY_EXPIRY,
]
const CACHE_NS_SEP = ':'
const CACHE_TL_NAME = 'ngin-bar'
const IN_FLIGHT_SPIN_MS = 100
const PAGINATION = 'PAGINATION'

const localStorage = window.localStorage
const sessionStorage = window.sessionStorage

const serialize = function(v) {
  return JSON.stringify(serializeMetadata(v))
}

const deserialize = function(v) {
  return deserializeMetadata(JSON.parse(v))
}

// Attach pagination metadata to the end of arrays before serializing
function serializeMetadata(v) {
  if (!Array.isArray(v) || !v._pagination) return v
  return v.concat({ meta: PAGINATION, data: v._pagination })
}

// Remove pagination metadata from the end of arrays and attach as _pagination
function deserializeMetadata(v) {
  const lastItem = Array.isArray(v) && v[v.length - 1] || {}
  if (lastItem.meta === PAGINATION) v._pagination = v.pop().data
  return v
}

let memoryMap = {}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const cacheKey = (...args) => args.join(CACHE_NS_SEP)
const cacheDataKey = (...args) => cacheKey(CACHE_TL_NAME, 'data', ...args)
const cacheCtrlKey = (...args) => cacheKey(CACHE_TL_NAME, 'ctrl', ...args)

const flushBrowserStorage = (storage) => {
  for (let i = 0; i < storage.length; i++) {
    let i
    const keysToFlush = []
    for (i = 0; i < storage.length; i++) {
      const key = storage.key(i)
      if (key.startsWith(CACHE_TL_NAME + CACHE_NS_SEP)) {
        keysToFlush.push(key)
      }
    }
    for (i = 0; i < keysToFlush.length; i++) {
      storage.removeItem(keysToFlush[i])
    }
  }
}

const cacheFuncMap = {
  memory: {
    get: (k) => memoryMap[k],
    set: (k, v) => (memoryMap[k] = v),
    del: (k) => delete memoryMap[k],
    flush: () => memoryMap = {},
  },
  sessionStorage: {
    get: (k) => sessionStorage.getItem(k),
    set: (k, v) => sessionStorage.setItem(k, v),
    del: (k) => sessionStorage.removeItem(k),
    flush: () => flushBrowserStorage(sessionStorage),
  },
  localStorage: {
    get: (k) => localStorage.getItem(k),
    set: (k, v) => localStorage.setItem(k, v),
    del: (k) => localStorage.removeItem(k),
    flush: () => flushBrowserStorage(localStorage),
  },
}

function lookupSessionHash() {
  for (const backend of CACHE_BACKENDS) {
    const sessionHash = cacheFuncMap[backend]['get'](cacheKey(CACHE_TL_NAME, CACHE_KEY_SESSION_HASH))
    if (sessionHash) { return sessionHash }
  }
}

function isSessionMismatched() {
  const cachedSessionHash = lookupSessionHash()
  const currentSessionHash = buildSessionHash()
  return cachedSessionHash && currentSessionHash
    ? cachedSessionHash != currentSessionHash
    : true
}

function markCacheSession(backend) {
  const sessionHash = buildSessionHash()
  if (sessionHash) {
    cacheFuncMap[backend]['set'](cacheKey(CACHE_TL_NAME, CACHE_KEY_SESSION_HASH), buildSessionHash())
  }
}

function beginCacheSession() {
  if (isSessionMismatched()) {
    flushCache()
  }
  markCacheSession(CACHE_DEFAULT_BACKEND)
}

function flushCache() {
  CACHE_BACKENDS.forEach((backend) => {
    cacheFuncMap[backend].flush()
  })
}

function cache({
  expiresInSeconds = CACHE_EXPIRY_DEFAULT_SECS,
  cacheBackend = CACHE_BACKEND_SESSION_STORAGE,
}) {
  const cacheExpiry = expiresInSeconds * ONE_SECOND

  const now = () => Date.now()

  const _get = (k) => deserialize(cacheFuncMap[cacheBackend]['get'](k))
  const _set = (k, v) => cacheFuncMap[cacheBackend]['set'](k, serialize(v))
  const _del = (k, v) => cacheFuncMap[cacheBackend]['del'](k)

  const getCacheData = (k) => _get(cacheDataKey(k))
  const setCacheData = (k, v) => _set(cacheDataKey(k), v)

  const getCacheExpiry = (k) => _get(cacheCtrlKey(k, 'x'))
  const setCacheExpiry = (k, v) => _set(cacheCtrlKey(k, 'x'), v)

  const getCacheTime = (k) => _get(cacheCtrlKey(k, 't'))
  const setCacheTime = (k) => _set(cacheCtrlKey(k, 't'), Date.now())
  const clearCacheTime = (k) => _del(cacheCtrlKey(k, 't'))

  const getPollId = (k) => _get(cacheCtrlKey(k, 'poll-id'))
  const setPollId = (k, v) => _set(cacheCtrlKey(k, 'poll-id'), v)
  const clearPollId = (k) => _del(cacheCtrlKey(k, 'poll-id'))

  const markInFlight = (k) => _set(cacheCtrlKey(k, 'in-flight'), true)
  const clearInFlight = (k) => _del(cacheCtrlKey(k, 'in-flight'))
  const isInFlight = (k) => _get(cacheCtrlKey(k, 'in-flight'))

  const hasExpired = (k) => now() - (getCacheTime(k) || 0) > getCacheExpiry(k)

  return (target, key, descriptor) => {
    clearInFlight(key)
    setCacheExpiry(key, cacheExpiry)

    let callbacks = []
    let pollOnPromise

    // attach *PollOn method
    Object.defineProperty(target, `${key}PollOn`, {
      value: function(cb = () => {}, args = []) {
        callbacks.push(cb)

        if (pollOnPromise) {
          if (!isInFlight(key)) pollOnPromise.then(cb)
          return
        }

        const callFunc = function() {
          pollOnPromise = Promise.resolve()
            .then(() => descriptor.value.call(this, ...args))
            .then((result) => {
              callbacks.forEach((cb) => cb(result))
              return result
            })
        }

        const pollFunc = function() {
          clearCacheTime(key)
          callFunc.call(this)
        }

        setPollId(key, setInterval(pollFunc.bind(this), cacheExpiry))
        callFunc.call(this)
      },
    })

    // attach *PollOff method
    Object.defineProperty(target, `${key}PollOff`, {
      value: function(cb = () => {}) {
        callbacks = callbacks.filter((fn) => fn !== cb)
        if (callbacks.length) return
        clearInterval(getPollId(key))
        pollOnPromise = null
      },
    })

    // attach *ClearCache method
    Object.defineProperty(target, `${key}ClearCache`, {
      value: function() {
        clearCacheTime(key)
      },
    })

    // attach *SetCache method -- updates cached data, but not the timeout
    Object.defineProperty(target, `${key}SetCache`, {
      value: function(data) {
        setCacheData(key, data)
        callbacks.forEach((cb) => cb(data))
      },
    })

    // modify the method to use cached values
    const original = descriptor.value
    const modified = function(...args) {
      if (isInFlight(key)) {
        // spin on parallel calls so that one get the cached value
        return delay(IN_FLIGHT_SPIN_MS).then(() => modified())
      }
      markInFlight(key)
      return Promise.resolve()
        .then(() => {
          if (hasExpired(key)) {
            clearCacheTime(key)
            return original.call(this, ...args)
          }
        })
        .then((result) => {
          if (result !== undefined) {
            setCacheData(key, result)
            setCacheTime(key)
          }
          clearInFlight(key)
          return getCacheData(key)
        })
        .catch((err) => {
          clearInFlight(key)
          throw err
        })
    }
    descriptor.value = modified
  }
}

export { cache, flushCache, beginCacheSession }
