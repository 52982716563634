import { fromEvent, Observable } from 'rxjs'
import { filter, map, share } from 'rxjs/operators'

import { ENV } from '../lib/env'

import { CrossDomainClientMessageType } from './cross-domain-client-message-type'
import { CrossDomainHostMessageType } from './cross-domain-host-message-type'
import { CrossDomainMessage, CrossDomainMessageType, CrossDomainMessageTypes } from './cross-domain-message'

const SE_DEV = /^https:\/\/[\w-]+\.sport(ngin|sengine)\.com\.dev$/

function getAllowedOriginPatterns(): RegExp[] {
  switch(ENV.current) {
    case 'production': return [/.*/] // vanity domains can be anything
    case 'staging': return [
      /\.sestage\.us$/,
      /\.stage\.ngin-staging\.com$/,
      /staging\..*$./,
      /\.fortheloveofsportsball.com$/,
      SE_DEV, // allow using staging SE Bar from dev
    ]
    default: return [
      /^http:\/\/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(:\d{2,5})?$/, // ip address, optional port
      /^http:\/\/localhost(:\d{2,5})?$/, // localhost, optional port
      SE_DEV,
    ]
  }
}

function validateMessageEvent(messageType: typeof CrossDomainHostMessageType | typeof CrossDomainClientMessageType): (event: MessageEvent) => boolean {
  const allowedMessageTypes = Object.values(messageType)
  const allowedOriginPatterns = getAllowedOriginPatterns()

  return (event: MessageEvent) => {
    if (!allowedMessageTypes.some(messageType => event.data.type === messageType)) {
      // console.log('[se-bar allowedMessages] disallowed message type', event.data.type, allowedMessageTypes)
      return false
    }

    // see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#Security_concerns
    if (!allowedOriginPatterns.some(allowedHostPattern => allowedHostPattern.test(event.origin))) {
      console.error('[se-bar allowedMessages] disallowed origin', event.origin, allowedOriginPatterns)
      return false
    }

    return true
  }
}

function toCrossDomainMessage<TMessageType extends CrossDomainMessageType>(event: MessageEvent): CrossDomainMessage<TMessageType, any> {
  return {
    data: event.data.data,
    event: event,
    type: event.data.type,
  }
}

export function allowedMessages<TMessageType extends CrossDomainMessageType, TMessage extends CrossDomainMessage<TMessageType, any>>(
  messageType: CrossDomainMessageTypes,
): Observable<CrossDomainMessage<TMessageType, any>> {
  return fromEvent<MessageEvent>(window, 'message').pipe(
    filter(validateMessageEvent(messageType)),
    map(event => toCrossDomainMessage<TMessageType>(event)),

    // reuse this source across multiple subscribers - otherwise, the whole chain is repeated,
    // including the "message" event handler, for each subscription
    share(),
  )
}
