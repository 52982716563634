import Service from '../lib/service'
import { cache } from '../lib/service-cache'
import { determineAvatar } from '../lib/service-helpers'
import request from '../lib/request'
import firebaseService from './firebase'

const SERVICE_POLL_INTERVAL_SECONDS = 60 * 10 // 10 minutes

function sortMessages(messages) {
  return messages.sort((messageA, messageB) => {
    const tA = Date.parse(messageA.message.sent_on)
    const tB = Date.parse(messageB.message.sent_on)
    if (tA < tB) return 1
    if (tA > tB) return -1
    return 0
  })
}

export class MessagesService extends Service {
  constructor() {
    super()
    window.addEventListener('message', this.onPostMessage, false)
  }

  setUser(user) {
    if (user.uuid) this.firebaseUnreadCount(user.uuid)
    else this.pollUnreadCount()
  }

  firebaseUnreadCount(uuid) {
    firebaseService
      .getData('seBar', `users/${uuid}/unreadMessageCount`)
      .then(data => data.on('value', this.onUnreadCount))
  }

  getMessages(force = false) {
    if (!force && this.messagesPromise) return this.messagesPromise

    const setAvatar = (message) => {
      message.sender_avatar = determineAvatar(message.from_user_profile_images)
    }
    const params = {
      mail_folder_name: 'Inbox',
      order_by: 'messages.sent_on',
      direction: 'desc',
      page: 1,
      hide_recipients: 1,
    }

    return this.messagesPromise = request
      .get('user_messages', { params })
      .then(sortMessages)
      .then((messages) => {
        // update unread count from pagination just in case it's out of sync
        const pagination = messages._pagination
        if (pagination && pagination.total_unread) {
          this.setUnreadCount(pagination.total_unread, true)
        }
        messages.forEach(setAvatar)
        this.emit('messages', messages)
        return messages
      })
  }

  onUnreadCount(snapshot) {
    if (snapshot.exists()) {
      this.setUnreadCount(snapshot.val())
    } else {
      this.pollUnreadCount()
    }
  }

  pollUnreadCount() {
    this.getUnreadCountPollOn(unreadCount => this.setUnreadCount(unreadCount))
  }

  // Fallback to firebase lookup
  @cache({ expiresInSeconds: SERVICE_POLL_INTERVAL_SECONDS })
  getUnreadCount() {
    return request
      .get('user_messages/unread')
      .then(data => data.unread_count)
  }

  setUnreadCount(unreadCount, fromPagination) {
    if (!fromPagination) delete this.messagesPromise
    this.emit('unreadCount', unreadCount, fromPagination)
  }

  onPostMessage(event) {
    const data = event.data || {}
    if (data.type === 'message:viewed') {
      this.getMessages(true)
    }
  }
}

// since services are singletons we instantiate here
const messagesService = new MessagesService()

export default messagesService
