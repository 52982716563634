import { Observable, Subject, Subscription } from 'rxjs'

import { MutexClientRequest, MutexLockAbortedError } from '../cross-domain'

import { GlobalSessionState } from './global-session-state'

export enum PingRequestProtection {
  byTraceId = 'byTraceId',
}

export class PingRequest {

  public get isProtected(): boolean {
    return !!this.protection
  }

  private _prevTraceId: string
  public get prevTraceId(): string {
    return this._prevTraceId
  }

  private _protection: boolean | PingRequestProtection
  public get protection(): boolean | PingRequestProtection {
    return this._protection
  }

  private readonly abort$$ = new Subject<never>()
  public readonly abort$ = this.abort$$.asObservable()

  constructor(
    prevTraceId: string,
    protection: boolean | PingRequestProtection,
    public readonly lockRequest$: MutexClientRequest,
    public readonly response$: Observable<GlobalSessionState>,
    private readonly subscription: Subscription,
  ) {
    this._prevTraceId = prevTraceId
    this._protection = protection
  }

  public protect(prevTraceId: string, protection: true | PingRequestProtection = true): void {
    // overwriting the existing prevTraceId ensures that if the original request had "byTraceId" protection, attempting
    // to abort in response to getting an updated session state object for the stale traceId will NOT abort the request
    // to update the new one
    this._prevTraceId = prevTraceId
    // only overwrite the existing protection if it is LESS specific than the new protection
    if (this._protection !== true) {
      this._protection = protection
    }
  }

  /**
   * Returns a boolean value representing whether the request can be aborted based on the specified {@param traceId}.
   *
   * This and the related "protection" properties allow the ping service to "match up" local requests with updates
   * coming in from other tabs, ensuring that important ping requests are completed without the need to duplicate them
   * across tabs.
   *
   * The response of each ping request is marked with the `trace_id` value from the API metadata. This value is then
   * stored with the {@link GlobalSessionState} object using Cross-Domain Storage. This has the effect of uniquely
   * identifying each iteration of the shared state. Additionally, each subsequent ping request is associated with the
   * `trace_id` of the state it is attempting to extend with the {@link prevTraceId} property.
   *
   * By using the Cross-Domain Mutex, {@link PingService} ensures that only one tab is ever making a ping request for a
   * given ping interval. However, there are use cases where a tab needs to be sure that a certain ping request is
   * completed, and without the `trace_id` data, could not trust any random Cross-Domain {@link GlobalSessionState}
   * update if did not get the lock the make the ping request itself.
   *
   * These use cases are:
   *   - the "initial ping" when the app first starts up
   *   - the ping made immediately before showing the inactivity dialog
   *   - the ping made to extend the session if the user click the button to extend the session from the
   *     inactivity dialog
   *   - the ping made immediately before ending the session due to expiration.
   *
   * Since the `trace_id` is included in both {@link PingRequest} and the resulting {@link GlobalSessionState}, it can
   * be used to determine whether one of these important ping requests were completed by another tab.
   *
   * An example flow would look like this:
   *   - The current {@link GlobalSessionState} has the `trace_id` value `abcdef`
   *   - Two different tabs (tab "A", and tab "B") attempt to initiate a ping request to extend the session state
   *     associated with the `trace_id` value `abcdef`
   *   - Due to using Cross Domain Mutex, one tab, tab "A", is allowed to make the request, while tab "B" is blocked
   *   - Tab "A" receives the ping response, and the updated {@link GlobalSessionState} is propagated to the centralized
   *     Cross Domain Storage, including the `trace_id` from the request as {@link GlobalSessionState.prev_trace_id}.
   *   - Tab "B" receives a notification about the updated {@link GlobalSessionState}
   *   - Since the `prev_trace_id` value matches the `trace_id` of Tab "B"'s current request, it can safely cancel its
   *     ping request and use the updated {@link GlobalSessionState} value
   *
   * @param traceId
   */
  public canAbort(traceId?: string): boolean {
    return !this.isProtected ||
      (this.protection === PingRequestProtection.byTraceId && this.prevTraceId === traceId)
  }

  public abort(): void {
    this.abort$$.error(new MutexLockAbortedError())
  }

  public dispose(): void {
    this.abort$$.complete()
    this.subscription.unsubscribe()
  }
}
