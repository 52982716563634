import Component from '../lib/component'
import Icon from './icon'
import ChatIndicatorDot from './chat-indicator-dot'
import chatService from '../services/chat'
import templateFn from './chat-menu-item.ejs'

class ChatMenuItem extends Component {

  constructor(options) {
    super(options)
    this.templateFn = templateFn
    this.messageCount = 0
  }

  init() {
    this.icon = new Icon({ parent: this, icon: 'chat', class: 'nb-list-item__icon' })
    this.indicator = new ChatIndicatorDot({ parent: this })
    this.submenuIcon = new Icon({ parent: this, icon: 'chevronRight', class: 'nb-list-item__submenu-icon' })
    chatService.on('unreadCount', this.update)
  }

  update(unreadCount) {
    if (this.messageCount === unreadCount) return
    this.messageCount = unreadCount
    if (this.element) this._update()
  }

}

export default ChatMenuItem
