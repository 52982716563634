import t from '../lib/i18n'
import MenuButton from './menu-button'
import MessagesDropdown from './messages-dropdown';
import MessagesIndicatorDot from './messages-indicator-dot'

class MessagesButton extends MenuButton {
  constructor(options) {
    super({
      parent: options.parent,
      dropdownClass: MessagesDropdown,
      label: t('MESSAGES.tooltip'),
      gaLabel: 'MessagesMenu',
      icon: 'envelope',
      indicatorClass: MessagesIndicatorDot
    })
  }
}

export default MessagesButton
