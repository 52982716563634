const throttle = (func, limit) => {
  let inThrottle
  return function() {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

const getAllProperties = (obj, type) => {
  if (!obj) return []
  let ret = []
  const props = Object.getOwnPropertyNames(obj)
  for (const prop of props) {
    // custom logic for getters since a lookup will run them, potentially before their data is ready
    const desc = Object.getOwnPropertyDescriptor(obj, prop) || {}
    const isGetterSetter = desc.hasOwnProperty('get') || desc.hasOwnProperty('set')
    if (type && (isGetterSetter || typeof obj[prop] !== type)) continue
    if (type === 'function' && prop === 'constructor') continue
    ret.push(prop)
  }
  return ret.concat(getAllProperties(Object.getPrototypeOf(obj), type))
}

const bindMethods = (obj) => {
  const methods = getAllProperties(obj, 'function')
  for (const method of methods) {
    try { obj[method] = obj[method].bind(obj) } catch(e) {}
  }
}

const findElement = (el, parent) => {
  parent = parent || document.documentElement
  if (el instanceof HTMLElement && parent.contains(el)) return el
  if (typeof el !== 'string') return null
  return parent.querySelector(el)
}

const createElementFromHTML = (htmlString) => {
  var renderer = document.createElement('div')
  renderer.innerHTML = htmlString.trim()
  return renderer.firstElementChild
}

const replaceElement = (oldEl, newEl) => {
  oldEl.parentNode.replaceChild(newEl, oldEl)
}

const omit = (obj, props) => {

  var keys = Object.keys(obj)
  var res = {}

  for (var i = 0; i < keys.length; i++) {
    var key = keys[i]
    var val = obj[key]

    if (!props.includes(key)) {
      res[key] = val
    }
  }
  return res
}

export {
  bindMethods,
  createElementFromHTML,
  findElement,
  getAllProperties,
  omit,
  replaceElement,
  throttle,
}
