import Dropdown from './dropdown'
import NotificationsList from './notifications-list'
import templateFn from './notifications-dropdown.ejs'

class NotificationsDropdown extends Dropdown {
  constructor(options) {
    super(options)
    this.templateFn = templateFn
  }

  init() {
    super.init()
    this.notificationsList = new NotificationsList({ parent: this })
  }

}

export default NotificationsDropdown
