export const APP_LOGO_PREFIX = 'nb-sportngin'
export const APP_HQ = 'hq'
export const APP_MY_SE = 'my-se'
export const APP_TEAM_CENTER = 'team-center'
export const APP_DEFAULT = 'DEFAULT'
export const APPS = [APP_HQ, APP_MY_SE, APP_TEAM_CENTER, APP_DEFAULT]

export const NOTIFICATION_TYPE_GUARDIAN_INVITE = 'guardian_invite'
export const NOTIFICATION_TYPE_ORPHAN_INVITE = 'orphan_invite'
export const NOTIFICATION_TYPE_ROSTER_INVITE = 'roster_invite'
export const NOTIFICATION_TYPE_TEAM_INVITE = 'team_invite'

export const NOTIFICATION_METHOD_ACCEPT = 'accept'
export const NOTIFICATION_METHOD_DECLINE = 'decline'

export const NOTIFICATION_MODE_DONE = 'done'
export const NOTIFICATION_MODE_PROCESSING = 'processing'

export const NOTIFICATION_VIEW_ACTION = 'action-progress'
export const NOTIFICATION_VIEW_INFO = 'view'

export const ACTION_STATUS_APPLICATION_ERROR = 'application-error'
export const ACTION_STATUS_CONNECTION_ERROR = 'connection-error'
export const ACTION_STATUS_OK = 'ok'
export const ACTION_STATUS_PROCESSING = 'processing'
export const ACTION_STATUS_RESPONSE_ERROR = 'response-error'
