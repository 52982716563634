import { fromEvent } from 'rxjs'
import { distinctUntilChanged, map, share, startWith } from 'rxjs/operators'

import authUrls from '../lib/auth-urls'
import Component from '../lib/component'
import location from '../lib/location'

const { templateFn } = require('./sign-in-button.ejs')

export class SignInButton extends Component {

  private _signInUrl: string
  public get signInUrl(): string {
    return this._signInUrl
  }

  private readonly locationChange$ = fromEvent(window, 'popstate').pipe(
    startWith(() => location.href),
    map(() => location.href),
    distinctUntilChanged(),
    share(),
  )

  constructor(parent: Component) {
    super({ parent })
    this.templateFn = templateFn

    this.trackSubscription(this.locationChange$, this.onLocationChange)
  }

  protected updateUrlProperties(): void {
    this._signInUrl = authUrls.getSignInUrl()
  }

  private onLocationChange() {
    this.updateUrlProperties()
    this._update()
  }
}
