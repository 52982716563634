import { Observable, Subject } from 'rxjs'

export interface TimerConstructor {
  new(): Timer
}

/**
 * An implementation of {@link Observable} that functions as a suspendable timer.
 */
export class Timer extends Observable<void> {

  public get remaining(): number {
    if (isNaN(this.duration)) {
      return undefined
    }
    return this.duration - (Date.now() - this.timerStarted)
  }

  private triggerTimeout: number
  private timerStarted: number
  private duration: number

  private resumeTimeout: number

  protected timer$$ = new Subject<void>()

  public get running(): boolean {
    return !!this.triggerTimeout
  }

  constructor() {
    super(subscriber => this.timer$$.subscribe(subscriber))
  }

  /**
   * Starts a timer that will cause this instance to emit after {@param duration} milliseconds
   *
   * Can only be called when the timer is not already running. Once called, it can only be called again after
   * first calling {@link suspend}, or after the Timer has emitted.
   */
  public start(duration: number): void {
    if (this.triggerTimeout) {
      throw new Error('Timer is already running')
    }
    this.stop()
    duration = duration < 0 ? 0 : duration
    this.duration = duration
    this.timerStarted = Date.now()
    this.triggerTimeout = setTimeout(this.trigger.bind(this), duration)
  }

  /**
   * Cancels any running timer and restarts using the specified {@param duration}
   */
  public restart(duration: number): void {
    this.stop()
    this.start(duration)
  }

  /**
   * Stops the running timer, optionally resuming after the specified {@param resumeTimeout} in milliseconds
   */
  public suspend(resumeTimeout?: number): void {
    if (!this.triggerTimeout) {
      return
    }
    this.cancelTriggerTimeout()

    if (resumeTimeout) {
      this.resumeTimeout = setTimeout(this.resume.bind(this), resumeTimeout)
    }
  }

  /**
   * Resumes a previously suspended timer, using the remaining time from the original duration
   */
  public resume(): void {
    if (isNaN(this.remaining)) {
      return
    }
    this.start(this.remaining)
  }

  /**
   * Stops the timer from emitting, including canceling any automatic resume scheduling by passing a timeout when
   * calling {@link suspend}
   */
  public stop(): void {
    this.cancelTriggerTimeout()
    this.cancelResumeTimeout()
    this.cleanup()
  }

  /**
   * Stops the timer (see {@link stop}) and completes the observable. The timer cannot be used again after calling
   * {@link dispose}.
   */
  public dispose(): void {
    this.stop()
    this.timer$$.complete()
  }

  /**
   * Allows the timer to be referenced as a regular {@link Observable} without also exposing the public control methods
   * of {@link Timer}
   */
  public asObservable(): Observable<void> {
    return this.pipe()
  }

  private trigger(): void {
    this.timer$$.next()
    this.stop()
  }

  private cancelResumeTimeout(): void {
    if (this.resumeTimeout) {
      clearTimeout(this.resumeTimeout)
      this.resumeTimeout = undefined
    }
  }

  private cancelTriggerTimeout(): void {
    if (this.triggerTimeout) {
      clearTimeout(this.triggerTimeout)
      this.triggerTimeout = undefined
    }
  }

  private cleanup(): void {
    this.duration = undefined
    this.timerStarted = undefined
  }

}
