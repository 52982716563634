import {merge, Observable, of, OperatorFunction, race, throwError} from 'rxjs'
import {catchError, filter, map, pairwise, share, startWith, switchMap, switchMapTo, take} from 'rxjs/operators'

import {MutexRequestCanceledError} from '../cross-domain'
import {AuthUrls} from '../lib/auth-urls'
import {SeBarConfig} from '../lib/config'
import {LocationService} from '../lib/location'
import {SubscriptionTracker} from '../services'

import {AuthenticationService} from './authentication.service'
import {
  AuthenticatedGlobalSessionState,
  GlobalSessionState,
  UnauthenticatedGlobalSessionState,
} from './global-session-state'
import {PingReason} from './ping-reason'
import {PingRequestProtection} from './ping-request'
import {PingDebugState, PingService} from './ping.service'
import {ensureDeps, SessionStateManagerDeps} from './session-state-manager-deps'
import {SessionStateService} from './session-state.service'
import {SignOutReason} from './sign-out-reason'
import {Timer, TimerConstructor} from './timer'
import {UserActivityService} from './user-activity.service'
import {UserState} from './user-state'
import {UserStateService} from './user-state.service'

let instance: SessionStateManager

/**
 * This service ties all session timeout functionality together, managing the scheduling of ping requests, as well as
 * emitting events for user inactivity and session expiration.
 */
export class SessionStateManager extends SubscriptionTracker {

  public static init(deps?: SessionStateManagerDeps): SessionStateManager {
    if (!instance) {
      const ctrDeps = ensureDeps(deps)
      instance = new SessionStateManager(...ctrDeps)
    }
    return instance
  }

  public static get instance(): SessionStateManager {
    return instance
  }

  public readonly userInactivityAlert$: Observable<AuthenticatedGlobalSessionState>
  public readonly sessionExpired$: Observable<GlobalSessionState>
  public readonly pingDebug$: Observable<PingDebugState>
  public readonly initialState$: Observable<GlobalSessionState>
  public readonly authState$: Observable<AuthenticatedGlobalSessionState>

  private readonly pingTimer: Timer
  private readonly sessionExpirationTimer: Timer

  private constructor(
    public readonly auth: AuthenticationService,
    private readonly authUrls: AuthUrls,
    private readonly location: LocationService,
    private readonly pingService: PingService,
    private readonly config: SeBarConfig,
    public readonly sessionState$: SessionStateService,
    TimerCtr: TimerConstructor,
    public readonly userActivity$: UserActivityService,
    public readonly userState$: UserStateService,
  ) {
    super()

    console.log(`[se-bar SessionStateManager] session timeout beta enabled`)
    this.pingDebug$ = this.pingService.debug$

    this.pingTimer = new TimerCtr()
    this.sessionExpirationTimer = new TimerCtr()

    this.authState$ = this.initAuthState()
    this.userInactivityAlert$ = this.initUserInactivityAlert()
    this.sessionExpired$ = this.sessionExpirationTimer.pipe(
      this.mapToCurrentGlobalSessionState(),
    )
    this.initialState$ = this.initInitialState()

    this.trackSubscription(this.initialState$)
    this.trackSubscription(this.userActivity$)

    // ignore any state updates that come before session start
    const state$ = merge(this.sessionState$.sessionStart$, this.sessionState$.sessionStart$.pipe(switchMapTo(this.sessionState$)))
    this.trackSubscription(state$, this.onGlobalSessionStateUpdate)
    this.trackSubscription(this.sessionState$.sessionStart$, this.onSessionStart)
    this.trackSubscription(this.sessionState$.sessionAuthenticatedNonReload$, this.onAuthenticatedNonReload)
    this.trackSubscription(this.sessionState$.sessionEnd$, this.onSessionEnd)

    this.trackSubscription(this.pingTimer, this.onPingTimer)
    this.trackSubscription(this.userInactivityAlert$, this.onInactivityAlert)
    this.trackSubscription(this.sessionExpirationTimer, this.onSessionExpirationTimer)
  }

  public dispose(): void {
    console.log('[se-bar SessionStateManager] dispose (shutting down)')
    super.dispose()

    this.pingTimer.dispose()
    this.sessionExpirationTimer.dispose()
    instance = undefined
  }

  public extendSession(): void {
    if (this.userState$.currentUserState === UserState.idle) {
      this.userActivity$.next({ ts: Date.now(), type: 'extend-session' })
      const currentState = this.sessionState$.currentSessionState as AuthenticatedGlobalSessionState

      // manually pad the alert interval so all tabs dismiss the warning
      this.sessionState$.next(Object.assign(currentState, {
        alert_after: Date.now() + 10000,
      }))

      this.doPing(PingReason.extendSession, PingRequestProtection.byTraceId)
    }
  }

  public manualPing(): Observable<GlobalSessionState> {
    return this.doPing(PingReason.manual)
  }

  private initAuthState(): Observable<AuthenticatedGlobalSessionState> {
    return this.sessionState$.pipe(
      startWith(of(undefined)), // required so that pairwise() emits after the first value
      pairwise(),
      filter(([prev, next]) => {
        let prevIsAuthed: boolean
        let prevId: number | string
        let nextIsAuthed: boolean
        let nextId: number | string
        if (AuthenticationService.isAuthenticated(prev)) {
          prevIsAuthed = true
          prevId = prev.id
        }
        if (AuthenticationService.isAuthenticated(next)) {
          nextIsAuthed = true
          nextId = next.id
        }

        // emit if authentication OR user id has changed
        return prevIsAuthed !== nextIsAuthed || prevId !== nextId
      }),
      map(([, next]) => AuthenticationService.isAuthenticated(next) ? next : undefined),
      share(),
    )
  }

  private initUserInactivityAlert(): Observable<AuthenticatedGlobalSessionState> {
    return this.userState$.pipe(
      filter(userState => userState === UserState.idle),
      switchMap(() => this.doPing(PingReason.lastBeforeAlert, PingRequestProtection.byTraceId)),
      map(pingResult => pingResult as AuthenticatedGlobalSessionState),
      share(),
    )
  }

  private initInitialState(): Observable<GlobalSessionState> {
    /**
     * note: initialPing$ gets canceled/aborted in {@link onGlobalSessionStateUpdate} if we receive an existing valid
     * session update from cross domain storage
     */
    const initialPing$ = this.doPing(PingReason.initial, PingRequestProtection.byTraceId)
    const sessionStart$ = this.sessionState$.sessionStart$.pipe(
      take(1),
      switchMap(state => {
        if (state.ping_after < Date.now()) {
          // if we missed the ping, do another one to ensure we don't get stuck in a pingless state
          // we cannot rely on another tab pinging for us, because it could have been closed
          return initialPing$
        }
        return of(state)
      }),
    )
    return race(initialPing$, sessionStart$)
  }

  private onGlobalSessionStateUpdate(state: GlobalSessionState): void {
    console.log('[se-bar SessionStateManager] onGlobalSessionStateUpdate', state)
    if (AuthenticationService.isAuthenticated(state)) {
      if (this.pingService.pingInProgress) {
        this.pingService.abort('received updated session state', state.prev_trace_id)
      }

      const now = Date.now()
      if (state.ping_after < now) {
        // this probably won't happen in real world scenarios, but when in dev/testing with extremely short session
        // lengths (e.g. 2 min or less), it can cause a weird feedback cycle where the negative interval causes the
        // ping to be repeatedly requested
        console.warn('[se-bar SessionStateManager] state update received with next ping in the past', state)
      } else if (state.alert_after > now) {
        const pingInterval = state.ping_after - now
        this.pingTimer.restart(pingInterval)
        console.log('[se-bar SessionStateManager] pinging in %sms', pingInterval)
      }
      this.sessionExpirationTimer.restart(state.expires_at - now)
    }
  }

  private onPingTimer(): void {
    console.log('[se-bar SessionStateManager] ping timer', Number(Date.now().toString().substring(6)))
    this.doPing(PingReason.timerInterval)
  }

  private doPing(reason: PingReason, protection: boolean | PingRequestProtection = false): Observable<GlobalSessionState> {
    console.log('[se-bar SessionStateManager] initiating ping (%s)', reason)
    this.pingTimer.suspend()
    this.sessionExpirationTimer.suspend()

    // Initial pings can be triggered before the cross-domain host can emit the last known state from cross-domain local
    // storage. In this case, just use PingReason.initial so that there is *something* to track as prev_trace_id.
    const traceId = this.sessionState$.currentTraceId ||
      (reason === PingReason.initial ? `${PingReason.initial}-${Math.random()}` : undefined)
    const sessionExpiresAt = AuthenticationService.isAuthenticated(this.sessionState$.currentSessionState) ?
      this.sessionState$.currentSessionState.expires_at :
      undefined

    const result = this.pingService.ping(traceId, sessionExpiresAt, protection).pipe(
      catchError(err => {
        if (MutexRequestCanceledError.isMutexCanceledError(err)) {
          // most likely due to receiving updated state from another tab
          // ignore the error and just return the current state object
          return of(this.sessionState$.currentSessionState)
        }
        return throwError(err)
      })
    )
    result.subscribe(state => this.sessionState$.next(state))
    return result
  }

  private onInactivityAlert(): void {
    const currentState = this.sessionState$.currentSessionState as AuthenticatedGlobalSessionState
    this.pingTimer.stop()
    console.warn('[se-bar SessionStateManager] user inactivity warning: logout in %sms', currentState.expires_at - Date.now())
  }

  private onSessionExpirationTimer(): void {
    console.log('[se-bar SessionStateManager] onSessionExpirationTimer')
    this.pingTimer.stop()
    this.auth.signOut(SignOutReason.inactivity)
  }

  private onSessionStart(): void {
    console.log('[se-bar SessionStateManager] onAuthenticatedReload obser')
    const returnTo = this.location.queryParam('user_return_to')
    if (returnTo) {
      this.location.navigateTo(returnTo)
    }
  }

  private onAuthenticatedNonReload(): void {
    console.log('[se-bar SessionStateManager] onAuthenticatedNonReload obser')
    if (this.authUrls.getSignInReloadUrl()) {
      this.location.navigateTo(this.authUrls.getSignInReloadUrl())
    }
  }

  private onSessionEnd(state: UnauthenticatedGlobalSessionState): void {
    console.log('[se-bar SessionStateManager] onSessionEnd')
    this.pingTimer.stop()
    this.sessionExpirationTimer.stop()
    this.pingService.abort('session ended')
    this.doSignOutRedirect(state?.reason || SignOutReason.userSignOut)
  }

  private doSignOutRedirect(reason: SignOutReason): void {
    if (reason === SignOutReason.inactivity) {
      // todo: ga tracking auto-logouts
    } else if (reason === SignOutReason.userSignOut) {
      // todo: ga tracking manual logouts
    }

    const origin = encodeURIComponent(this.location.hrefFiltered)
    const sessionEndedUrl = `${this.config.urls.userService}/session_ended?user_return_to=${origin}`
    return this.location.navigateTo(this.authUrls.getSignOutUrl(sessionEndedUrl))
  }

  private mapToCurrentGlobalSessionState<TState extends GlobalSessionState>(): OperatorFunction<unknown, TState> {
    return map(() => this.sessionState$.currentSessionState as TState)
  }

}
