import Service from '../lib/service'
import { cache } from '../lib/service-cache'
import firebaseService from './firebase'
import request from '../lib/request'

export class ChatService extends Service {


  constructor() {
    super()
  }

  setUser(user) {
    if (user.uuid) this.firebaseUnreadCount(user.uuid)
  }

  firebaseUnreadCount(uuid) {
    firebaseService
      .getData('chat', `user-unread/${uuid}`)
      .then(data => data.on('value', this.onUnreadChannels))
      .catch(err => console.log(err))
  }

  getChats(force = false) {
    if (!force && this.chatsPromise) return this.chatsPromise

    const params = {
      include_favorites: '1',
      with_retired: '1',
      originator_system: 'Ngin,TeamService,StatNgin'
    }

    return this.teamsPromise = request
      .get('v3/teams/mine', { params })
      .then((teams) => {

        teams.forEach(team => {
          team.unreadCount = 0
          team.lastReadMessage = undefined

          team.avatar = {
            imgUrl: team.logo && team.logo.image_urls && team.logo.image_urls.large || null,
            identity: team.name,
            type: team.originator_type,
            sport: team.sport,
            color: team.primary_color,
            size: 300 // 48px
          }

          if (!this.channels) return

          const teamChannel = this.channels[`${team.id}`]

          if (teamChannel) {
            team.unreadCount = teamChannel.unreadCount
            team.lastReadMessage = new Date(teamChannel.lastReadMessageTimestamp)
          }
        })

        teams.sort((a,b) => {
          return ((b.unreadCount || 0) - (a.unreadCount || 0))
        })

        this.emit('teams', teams)

        return teams
      })
  }

  onUnreadChannels(snapshot) {
    if (snapshot.exists()) {
      let unreadCount = 0
      this.channels = snapshot.val().channels

      Object.values(this.channels)
        .forEach(c => (this.isActiveTeamChannel(c)? unreadCount += c.unreadCount || 0 : 0))

      this.setUnreadCount(unreadCount)
    }
  }

  isActiveTeamChannel(channel) {
    if (channel.teamStatus && channel.teamDisplayStatus) {

      return (channel.teamStatus=='active' && channel.teamDisplayStatus=='public') ||
        (channel.teamStatus=='active' && channel.teamDisplayStatus=='private') ||
        (channel.teamStatus=='inactive' && channel.teamDisplayStatus=='public')

    } else {

      return true // Temp until all the data is fixed

    }
  }


  setUnreadCount(unreadCount) {
    this.emit('unreadCount', unreadCount)
  }
}

// since services are singletons we instantiate here
const chatService = new ChatService()

export default chatService
